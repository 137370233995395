import React from 'react';
import { connect } from 'react-redux';
import Link from '../../Helper/Link';

const primeNav = [
  { route: 'BUILDS', title: 'Software', subRoutes: ['PRODUCT_CODE', 'BUILD_DETAILS', 'BUILD_CHANGELOG'] },
  { route: 'VRSCANS', title: 'Chaos Scans', subRoutes: ['VRSCAN'] },
];

function Menu({ routing }) {
  return (
    <ul className="nav">
      {primeNav.map((params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <MenuItem {...params} currentRouteId={routing.id} key={params.route} />
      ))}
    </ul>
  );
}

function MenuItem({ route, title, currentRouteId }) {
  return (
    <li>
      <Link action={route} className={isCurrentRoute(route, currentRouteId) ? 'active' : null}>
        {title}
      </Link>
    </li>
  );
}

function isCurrentRoute(routeId, currentRouteId) {
  const navItem = primeNav.find((item) => item.route === routeId);
  if (!navItem) {
    return false;
  }
  if (navItem.route === currentRouteId) {
    return true;
  }
  if (navItem.subRoutes && navItem.subRoutes.indexOf(currentRouteId) > -1) {
    return true;
  }
  return false;
}

export default connect((state) => ({ routing: state.routing }))(Menu);
