/* eslint-disable no-param-reassign */
import { put, call, select, fork } from 'redux-saga/effects';
import { Api } from '../Utils/Api';
import { watchRouteChange } from '../App/appSagas';
import { handleLanguage } from '../../sagas';
import { collections } from '../Utils/collections';

export function* navigateBuildDetails() {
  const route = yield select((state) => state.routing);
  yield fork(watchRouteChange);
  yield fork(handleLanguage);
  yield put({ type: 'BUILDS_SEARCH#CLEAR' });
  yield call(fetchBuilds, route.params.id);
}

export function* fetchBuilds(id) {
  try {
    yield put({ type: 'BUILD_DETAILS#START' });
    const data = yield call(Api.fetch, `/builds/${id}/related`);
    const relatedBuilds = data.RelatedBuilds.map((b) => {
      if (b.platformVersions === undefined) {
        b.platformVersions = [];
      }
      b.platformVersionName = collections.toSentence(b.platformVersions.map((v) => v.name));
      return b;
    });
    yield put({ type: 'BUILD_DETAILS#COMPLETE', data: relatedBuilds });
  } catch (error) {
    yield put({ type: 'BUILD_DETAILS#ERROR', error });
  }
}
