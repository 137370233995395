/* eslint-disable no-param-reassign */
/* eslint-disable prefer-spread */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { detect } from './Detect';

const RENDERERS = {
  0: 'Defscanline',
  1: 'V-Ray 1.0',
  2: 'V-Ray 1.5',
  3: 'V-Ray 2.0',
  4: 'V-Ray 2.4',
  5: 'V-Ray 3.0',
  6: 'V-Ray Next',
};
const VRAY_NEXT_VERSION = '4.0.0';
const VRAY_NEXT_LABEL = 'NEXT';

export const LICENSE_TYPES = {
  adv: { key: 0, value: 'Trial / Commercial' },
  edu: { key: 2, value: 'Educational' },
  free: { key: 3, value: 'Free' },
  demo: { key: 4, value: 'Demo' },
  license_type_beta: { key: 5, value: 'Beta' },
  beta: { key: 5, value: 'Beta' },
  dev: { key: 6, value: 'Development' },
  trial: { key: 7, value: 'Trial' },
  ple: { key: 8, value: 'Personal Learning Edition' },
  integrators: { key: 9, value: 'Integrators' },
};

export const RELEASE_TYPES = {
  0: 'Official release',
  1: 'Beta',
  2: 'Nightly build',
  3: 'Custom build',
  4: 'Preview',
};

// platforms that needs special sorting
// for some versions
const EXCEPTION_PLATFORMS = [
  49, // 3dsMax
  57, // Softimage
];

// versions that needs to be sorted at the bottom of the list
export const EXCEPTION_VERSIONS = [
  '9.0', // 3dsMax 9.0
  '75', // Softimage 75
];

// Platforms that needs to be sortet by version
const PLATFORMS_SORT_BY_VERSION = [
  51, // Rhino
];

export const addGroupName = (items) => {
  return items.map((item) => {
    let groupName = [];

    if (item.displayName && item.displayName !== '') {
      groupName.push(item.displayName);
    } else {
      groupName.push(item.product.name);
      groupName.push(expressTitle(item));
      groupName.push(item.version);
      if (RENDERERS[item.renderer]) {
        groupName.push(RENDERERS[item.renderer]);
      }
      groupName.push(getLicenseType(item));
      groupName = groupName.filter((i) => i);
    }

    item.groupName = groupName.join(' ');
    groupName.push(item.platformVersionName);
    item.groupKey = groupName.join(' ');

    return item;
  });
};

const expressTitle = (build) => {
  if (build.name.indexOf('Express') !== -1) {
    return 'Express';
  }

  return null;
};

const getLicenseType = (build) => {
  if (
    !build.licenseType.name ||
    (build.licenseType.name && build.licenseType.name.toLowerCase() === 'free') ||
    (build.licenseType.name &&
      build.licenseType.name.toLowerCase() === 'adv' &&
      compareVersions(build.version, VRAY_NEXT_VERSION) >= 0)
  ) {
    return '';
  }
  return build.licenseType.name.replace('license_type_', '').toUpperCase();
};

export const fullTitle = (build) => {
  if (!build.product) {
    return null;
  }
  if (build.displayName && build.displayName !== '') {
    return build.displayName;
  }

  let bname = [];
  bname.push(build.product.name);
  bname.push(expressTitle(build));
  bname.push(build.version);
  if (RENDERERS[build.renderer]) {
    bname.push(RENDERERS[build.renderer]);
  }
  if (build.platform.name.toLowerCase() !== 'standalone') {
    bname.push('for');
  }
  bname.push(build.platform.name);
  if (build.platformVersionName) {
    bname.push(build.platformVersionName);
  }
  bname.push(getLicenseType(build));
  bname = bname.filter((i) => i);

  return bname.join(' ');
};

// eslint-disable-next-line complexity
const compareVersions = (a, b) => {
  if (a === VRAY_NEXT_LABEL && b === VRAY_NEXT_VERSION) {
    return 1;
  }

  let aParts = a.split('.');
  let bParts = b.split('.');

  if (!aParts.every((x) => /^\d+$/.test(x)) || !bParts.every((x) => /^\d+$/.test(x))) {
    return NaN;
  }
  aParts = aParts.map(Number);
  bParts = bParts.map(Number);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < aParts.length; ++i) {
    if (bParts.length === i) {
      return 1;
    }

    if (aParts[i] === bParts[i]) {
      // eslint-disable-next-line no-continue
      continue;
    } else if (aParts[i] > bParts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (aParts.length !== bParts.length) {
    return -1;
  }

  return 0;
};

export const productImage = (build) => {
  const productName = build.product.name === 'Enscape Preview' ? 'Enscape' : build.product.name;

  return `/images/products/${productName.toLowerCase().replace(/[^a-z\d]+/g, '')}_60px.svg`;
};

export const platformImage = (build) => {
  return `/images/platforms/${build.platform.name.toLowerCase().replace(/[^a-z\d]+/, '')}.png`;
};

export const osImage = (build, black = false) => {
  return `/images/os/${build.os.name.toLowerCase().replace(/[^a-z\d]+/, '')}${black ? '_b' : ''}.svg`;
};

export const largeProductImage = (build) => {
  if (!build.product) {
    return null;
  }
  const productName = build.product.name === 'Enscape Preview' ? 'Enscape' : build.product.name;

  const product = productName.toLowerCase().replace(/[^a-z\d]+/g, '');
  const platform = build.platform.name.toLowerCase().replace(/[^a-z\d]+/g, '');
  let imageName = platform;
  if (product === 'phoenixfd') {
    imageName = product + platform;
  } else if (
    [
      'vrayappsdk',
      'vraycloud',
      'vraybenchmark',
      'chaosvantage',
      'licenseserver',
      'pdplayer',
      'chaosplayer',
      'vrayswarm',
      'enscape',
      'animaall',
    ].includes(product)
  ) {
    imageName = product;
  }
  return `/images/builds/logos_120px/${imageName}.svg`;
};

export const groupBuilds = (builds, groupBy, selectedPlatformVersion) => {
  let newBuilds = addGroupName(builds);
  if (groupBy === 'platform') {
    newBuilds = sortBuilds(newBuilds);
  } else {
    // Sort by release date
    newBuilds = newBuilds.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));
  }

  const grouped = newBuilds.reduce((r, a) => {
    r[a.groupKey] = r[a.groupKey] || [];
    r[a.groupKey].push(a);
    return r;
  }, Object.create(null));

  // eslint-disable-next-line guard-for-in
  for (const key in grouped) {
    const maxVersion = Math.max.apply(
      Math,
      builds.map((b) => {
        // group by selected platform version will show latest build who has this platform version
        if (selectedPlatformVersion > 0) {
          return grouped[key][0].platformVersions.map((v) => v.id).includes(selectedPlatformVersion)
            ? convertVersion(b.version)
            : null;
        }

        // group by platform version name when platform version is not selected
        return grouped[key][0].platformVersionName === b.platformVersionName ? convertVersion(b.version) : null;
      }),
    );
    const version = convertVersion(grouped[key][0].version);
    grouped[key] = {
      id: grouped[key][0].id,
      name: grouped[key][0].groupName,
      platform: grouped[key][0].platform,
      platformVersionName: grouped[key][0].platformVersionName,
      releaseType: grouped[key][0].releaseType,
      releaseDate: grouped[key][0].releaseDate,
      version,
      isNew: version === maxVersion,
      items: grouped[key],
    };
  }

  return grouped;
};

const sortBuilds = (builds) => {
  let sorted = builds.sort((a, b) =>
    a.platformVersionName !== b.platformVersionName
      ? a.platformVersionName > b.platformVersionName
        ? -1
        : 1
      : new Date(b.releaseDate) - new Date(a.releaseDate),
  );

  if (sorted.length > 0 && PLATFORMS_SORT_BY_VERSION.includes(sorted[0].platform.id)) {
    sorted = builds.sort((a, b) =>
      a.version !== b.version
        ? convertVersion(a.version) > convertVersion(b.version)
          ? -1
          : 1
        : new Date(b.releaseDate) - new Date(a.releaseDate),
    );
  }

  if (sorted.length > 0 && EXCEPTION_PLATFORMS.includes(sorted[0].platform.id)) {
    sorted.forEach((build, i) => {
      if (EXCEPTION_VERSIONS.includes(build.platformVersionName)) {
        sorted.push(sorted.splice(i, 1)[0]);
      }
    });
  }

  return sorted;
};

export const defaultBuild = (builds) => {
  const detected = detect();
  let dBuild = builds.find(
    (build) =>
      build.os.name === detected.os &&
      (build.architecture.name === detected.architecture || build.architecture.name === 'universal'),
  );
  if (!dBuild) {
    // eslint-disable-next-line prefer-destructuring
    dBuild = builds[0];
  }

  return dBuild;
};

const convertVersion = (version) => {
  let newVersion = '';

  version
    .replace(/[a-z]/i, '')
    .split('.')
    .forEach((val) => {
      newVersion += val.padEnd(2, '0').padStart(4, '0').substring(0, 6);
    });

  return parseInt(newVersion.padEnd(16, '0'), 10);
};

const sluggify = (str) => {
  return str
    .toString()
    .toLowerCase()
    .trim()
    .replace('license_type_', '')
    .replace(/[\s\W-_]+/g, '');
};

export const buildSlug = (build) => {
  let slug = [];
  slug.push(build.product.name);
  slug.push(build.platform.name);
  if (build.platformVersionName) {
    slug.push(build.platformVersionName);
  }
  slug.push(build.version);
  slug.push(build.licenseType.name);
  slug = slug.filter((i) => i).map((s) => sluggify(s));

  return slug.join('-');
};

export const generateBuildDownloadId = (product, platform, version) => {
  const productName = product.name.replaceAll(' ', '').replaceAll('-', '').toLowerCase();
  const platformName = platform.name.replaceAll(' ', '').toLowerCase();
  return `download-downloads-${productName}-${platformName}-${version}`;
};
