export const strings = {
  toCamelCase(s) {
    return s.replace(/(_\w)/g, (m) => m[1].toUpperCase());
  },
  toSnakeCase(s) {
    return s.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);
  },
  toHumanFileSize(n) {
    let i = -1;
    let fileSize = n;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSize /= 1024;
      i += 1;
    } while (fileSize > 1024);

    return Math.max(fileSize, 0.1).toFixed(1) + byteUnits[i];
  },
};
