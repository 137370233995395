import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { saga as routerSaga } from 'redux-saga-first-router';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { MuiThemeProvider } from '@material-ui/core/styles';

import { primary } from './app/Utils/themes';
import App from './app/App/App';
import { translations } from './i18n/translations';

import { sagaMiddleware, rootSaga } from './sagas';
import { routesMap } from './routes';
import store from './store';

i18n.use(initReactI18next).init({
  resources: translations,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  nsSeparator: false,
  keySeparator: false,
});

sagaMiddleware.run(rootSaga);

const h = createBrowserHistory();
sagaMiddleware.run(routerSaga, routesMap, h);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={primary}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
