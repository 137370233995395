const initialState = {
  builds: [],
  buildsGroups: {},
  loading: false,
  error: null,
  filter: {},
  filterParams: {},
  filterOptions: {
    title: '',
    platformVersions: [],
    licenseTypes: [],
  },
};

// eslint-disable-next-line complexity
export function builds(state = initialState, payload) {
  switch (payload.type) {
    case 'BUILDS#FILTER#SET':
      return { ...state, filter: payload.data };
    case 'BUILDS#FILTER#ADD':
      return { ...state, filter: { ...state.filter, ...payload.data } };
    case 'BUILDS#FILTER#CLEAR':
      return { ...state, filter: { title: '', platformVersions: [], licenseTypes: [] }, builds: [], buildsSearch: [] };

    case 'BUILDS#FILTER#PARAMS#ADD':
      return { ...state, filterParams: { ...state.filterParams, ...payload.data } };
    case 'BUILDS#FILTER#PARAMS#CLEAR':
      return { ...state, filterParams: {} };

    case 'BUILDS#FILTER#OPTIONS#PLATFORM':
      return { ...state, filterOptions: { ...state.filterOptions, platformVersions: payload.data } };
    case 'BUILDS#FILTER#OPTIONS#LICENSETYPE':
      return { ...state, filterOptions: { ...state.filterOptions, licenseTypes: payload.data } };
    case 'BUILDS#FILTER#OPTIONS#CLEAR':
      return { ...state, filterOptions: initialState.filterOptions };

    case 'BUILDS#START':
      return { ...state, loading: true };
    case 'BUILDS#COMPLETE':
      return { ...state, builds: payload.data, loading: false };
    case 'BUILDS#GENERATE':
      return { ...state, buildsGroups: payload.data };
    case 'BUILDS#GENERATE#CLEAR':
      return { ...state, buildsGroups: {} };
    default:
      return state;
  }
}
