import { createTheme } from '@material-ui/core/styles';

export const primary = createTheme({
  typography: {
    fontFamily: `"Poppins", sans-serif`,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    useNextVariants: true,
    fontSize: 13,
    htmlFontSize: 13,
  },
  palette: {
    primary: {
      light: '#7986cb',
      main: '#0db5ff',
      dark: '#05a2e7',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rbga(0, 0, 0, .6)',
      main: '#1C1C1C',
      dark: '#1C1C1C',
      contrastText: '#fff',
    },
    error: {
      light: '#f44336',
      main: '#cc3333',
      dark: '#cc3333',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '.8em',
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, .8)',
        borderRadius: 4,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        padding: '2px 12px',
        color: '#1C1C1C',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 4,
      },
    },
    MuiChip: {
      root: {
        height: 36,
        borderRadius: 999,
        fontSize: 13,
        padding: '8px 15px',
      },
      deletable: {
        color: '#1C1C1C',
        backgroundColor: '#FFFFFF',
        border: '1px solid #EDEDED',
        '&:focus': {
          backgroundColor: '#FFFFFF',
        },
        '&:hover': {
          borderColor: '#0DB5FF',
        },
      },
      deleteIcon: {
        height: 15,
        fill: '#EDEDED',
        '&:hover': {
          fill: '#0DB5FF',
        },
      },
      avatar: {
        width: 12,
        height: 12,
      },
    },
  },
  shadows: Array(25).fill('none'),
});

export const blackAndWhite = createTheme({
  typography: {
    fontFamily: `"Poppins", sans-serif`,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: 'rbga(0, 0, 0, .6)',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgba(0, 0, 0, .6)',
    },
  },
});
