import { call, put, cancel } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';

const defaultFetchOptions = {
  host: window.API,
  parseJSON: true,
  onUnauthorized: 'redirect',
  on4xx: 'throw',
  on5xx: 'API_ERROR',
  credentials: 'include',
};

export const Api = {
  // eslint-disable-next-line complexity
  *fetch(path, options = {}) {
    const { host, parseJSON, onUnauthorized, on4xx, on5xx, retryHandler, ...request } = {
      ...defaultFetchOptions,
      ...options,
    };
    const address = (host || window.MYCHAOS_BACKEND_ADDRESS) + path;

    let response = null;
    try {
      response = yield call(fetch, address, request);
    } catch (err) {
      yield call(handleApiError, on5xx, null, retryHandler);
    }

    if (response.status === 404) {
      return yield put(navigate('404'));
    }
    if (response.status === 500) {
      return yield put(navigate('500'));
    }

    if (response.status === 403 || response.status === 401) {
      return yield call(handleApiError, onUnauthorized, response, retryHandler);
    }

    if (/^4[0-9]{2}$/.exec(response.status)) {
      return yield call(handleApiError, on4xx, response, retryHandler);
    }
    if (/^5[0-9]{2}$/.exec(response.status)) {
      return yield call(handleApiError, on5xx, response, retryHandler);
    }

    yield put({ type: 'API_ERROR#RESET' });

    if (parseJSON) {
      return yield call(() => response.json());
    }

    return response;
  },
};

function* handleApiError(action, response, retryHandler) {
  if (action === 'redirect') {
    window.location = `${window.SSO_FRONTEND}/service_login?return_to=${encodeURIComponent(window.location.href)}`;
    yield cancel();
  }

  let error;
  if (response) {
    error = yield call(responseError, response);
  } else if (navigator.onLine === false) {
    error = {
      message: 'No Internet connection. Please check the network cables, modem and router or reconnect to Wi-Fi.',
    };
  } else {
    error = {
      message: 'We are having technical difficulties. Please try later.',
    };
  }

  if (action === 'throw') {
    throw error;
  }

  yield put({ type: action, data: { error, retryHandler } });

  yield cancel();
}

function* responseError(response) {
  const error = {
    HTTPStatus: response.status,
    message: '',
  };

  if (response.headers.get('content-type').indexOf('application/json') === 0) {
    const responseBody = yield call(() => response.json());
    return { ...error, ...(responseBody.error ? responseBody.error : responseBody) };
  }

  const responseText = yield call(() => response.text());
  return { ...error, message: ['An unexpected error occurred.', responseText].join(' ') };
}
