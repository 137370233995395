export const cn = {
  translation: {
    'Select your product.': '选择您的产品.', // New
    'Select the version.': 'Select the version.',
    'Select the version of your host platform.': 'Select the version of your host platform.',
    Download: '下载',
    Downloads: '下载',
    'Back to all products': '返回全部产品', // New
    'Use the filter to find your product': '使用筛选功能找到您的产品', // New
    'See all products': '查看全部产品', // New
    'Download for another OS': '其他操作系统',
    'Download for': '适用版本',
    'Show all versions': '显示所有版本', // New
    'All fields are required': '所有字段都要填写', // New
    'Platform version': '平台版本',
    'License type': '许可类型',
    'Please select': '请选择', // New
    Search: '搜索', // New
    '1 item found': '1 结果',
    '{n} items found': '{{n}} 个结果',
    'Release date': '发布日期',
    'File Size': '文件大小',
    'File size': '文件大小',
    "What's new": '版本新增功能',
    'Requirements & changelog': '系统需求 & 改动日志',
    items: '结果',
    new: 'new', // New
    'Uploaded before': '之前上传', // New
    'Material type': '材质类型',
    'Material color': '材质颜色',
    'Suitable for': '适用于',
    Manufacturer: '制造业',
    Manufacturers: '制造商', // New
    Tags: '标签',
    'Clear all': '清除全部',
    'File name': '文件名称',
    'Note: Due to manufacturing process and use of natural materials, final color may vary. Please reach out to the manufacturer for more details.':
      '由于制造过程的性质和天然材料的使用，物理材料的实际颜色和图案可能变化。',
    'Search Chaos Scans': '搜索 Chaos Scans',
    'show all results': '查看全部结果',
    'No results found.': '没有找到结果',

    // Filters
    '1 Week': '1周', // New
    '1 Month': '1个月', // New
    '6 Months': '6个月', // New

    'Car Paint': '车漆',
    Carbon: '碳纤维',
    Ceramic: '陶瓷',
    Fabric: '布料',
    Foam: '泡沫塑料',
    Foil: '金属箔',
    Hologram: '全息图',
    Leather: '皮革',
    Metal: '金属',
    Paint: '涂料',
    Paper: '纸张',
    Plastic: '塑料',
    Rubber: '橡胶',
    Wood: '木材',

    Automotive: '汽车',
    'Interior Design': '室内设计',
    'Product Design': '产品设计',
    Aerospace: '航天', // New

    Generic: '通用',
    'Townsend Leather Company, Inc.': 'Townsend Leather Company, Inc.',
    'Handy Living': 'Handy Living',
    'Kawashima Selkon Textiles Co., Ltd.': 'Kawashima Selkon Textiles Co., Ltd.',
    'Nihon Etching Co., Ltd.': 'Nihon Etching Co., Ltd.',
    'Tanazawa Hakkosha Co., Ltd': 'Tanazawa Hakkosha Co., Ltd',

    Alcantara: '欧缔兰',
    Aluminum: '铝',
    Brass: '黄铜',
    Brushed: '拉丝',
    Copper: '铜',
    Crocodile: '鳄鱼皮',
    Galvanized: '镀锌',
    Glossy: '光泽',
    Gold: '金',
    Matte: '亚光',
    Mirror: '镜面',
    Pattern: '图案',
    Perforated: '穿孔的',
    Rust: '锈',
    Scratched: '划痕',
    Tin: '锡',
    Volumetric: '体积',
    'V-Ray Next Recommended': '推荐 V-Ray Next', // New
    Wool: '羊毛', // New
    Retroreflective: '逆向反射材质', // New
    Chameleon: '变色材质', // New
    Grain: '纹理', // New
    Flat: '平面', // New
    Smooth: '光滑', // New
    Translucent: '半透明', // New

    // Colors
    White: '白色',
    Yellow: '黄色',
    Beige: '米色',
    Orange: '橙色',
    Fuchsia: '紫红色',
    Red: '红色',
    Pink: '粉色',
    Silver: '银色',
    Gray: '灰色',
    Olive: '橄榄绿',
    Purple: '紫色',
    Maroon: '栗色',
    Aqua: '青蓝色',
    Lime: '浅绿色',
    Teal: '灰绿色',
    Green: '绿色',
    Blue: '蓝色',
    Navy: '深蓝色',
    Brown: '棕色',
    Black: '黑色',
  },
};
