/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropType from 'prop-types';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import IconClear from '@material-ui/icons/Clear';

function Filters({ filters, activeFilters, onChange, t }) {
  return (
    <div className="vrscan-filters">
      <ListFilter
        title={t('Uploaded before')}
        filterItems={filters.uploadedScope}
        activeFilterItems={activeFilters.uploadedScope}
        onChange={(id, value) => onChange('uploadedScope', id, value)}
      />
      <ListFilter
        title={t('Material type')}
        filterItems={filters.materialType}
        activeFilterItems={activeFilters.materialType}
        onChange={(id, value) => onChange('materialType', id, value)}
      />
      <ColorsFilter
        title={t('Material color')}
        filterItems={filters.colors}
        activeFilterItems={activeFilters.colors}
        onChange={(id, value) => onChange('colors', id, value)}
      />
      <ListFilter
        title={t('Suitable for')}
        filterItems={filters.industries}
        activeFilterItems={activeFilters.industries}
        onChange={(id, value) => onChange('industries', id, value)}
      />
      <ListFilter
        title={t('Manufacturers')}
        filterItems={filters.manufacturer}
        activeFilterItems={activeFilters.manufacturer}
        onChange={(id, value) => onChange('manufacturer', id, value)}
      />
      <ListFilter
        title={t('Tags')}
        filterItems={filters.tags}
        activeFilterItems={activeFilters.tags}
        onChange={(id, value) => onChange('tags', id, value)}
      />
    </div>
  );
}

Filters.propTypes = {
  filters: PropType.shape({
    uploadedScope: PropType.objectOf(Map),
    materialType: PropType.objectOf(Map),
    colors: PropType.objectOf(Map),
    industries: PropType.objectOf(Map),
    manufacturer: PropType.objectOf(Map),
    tags: PropType.objectOf(Map),
  }).isRequired,
  activeFilters: PropType.shape({
    uploadedScope: PropType.object,
    materialType: PropType.object,
    colors: PropType.object,
    industries: PropType.object,
    manufacturer: PropType.object,
    tags: PropType.object,
  }).isRequired,
  onChange: PropType.func.isRequired,
};

const ListFilter = withTranslation()(({ title, filterItems, activeFilterItems, onChange, t }) => {
  return (
    <div className="filter">
      <h4 className="option-heading">{title}</h4>
      <ul className="options-list">
        {Array.from(filterItems).map((filter) => (
          <FormGroup row key={filter[0].toString()}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(activeFilterItems[filter[0]])}
                  value={filter[0].toString()}
                  onChange={(_, checked) => onChange(filter[0], checked)}
                />
              }
              label={t(filter[1].name)}
            />
          </FormGroup>
        ))}
      </ul>
    </div>
  );
});

ListFilter.defaultProps = {
  filterItems: {},
  activeFilterItems: {},
};

const ColorsFilter = withTranslation()(({ title, filterItems, activeFilterItems, onChange, t }) => {
  return (
    <div className="filter">
      <h4 className="option-heading">{title}</h4>

      <ul className="options-list color-list">
        {Array.from(filterItems).map((filter) => (
          <Tooltip key={filter[1].name} title={t(filter[1].name)} placement="top">
            <li
              className={`color ${filter[1].name.toLowerCase()} ${
                activeFilterItems[filter[0]] ? 'selected icon-tick' : ''
              }`}
              onClick={() => onChange(filter[0], !activeFilterItems[filter[0]])}
            />
          </Tooltip>
        ))}
      </ul>
    </div>
  );
});

ColorsFilter.defaultProps = {
  filterItems: {},
  activeFilterItems: {},
};

export const ActiveFilters = withTranslation()(({ filters, activeFilters, onDelete, onClearAll, t }) => {
  if (Object.keys(activeFilters).length === 0) {
    return <></>;
  }

  return (
    <Grid container spacing={2} className="active-filters" alignItems="center">
      {Object.keys(activeFilters).map((k) =>
        Object.keys(activeFilters[k]).map((v) => (
          <Grid item key={parseInt(v, 10)}>
            <Chip
              label={filters[k].get(parseInt(v, 10)).name}
              avatar={k === 'colors' ? <Avatar className={`color ${filters[k].get(parseInt(v, 10)).key}`} /> : null}
              onDelete={() => onDelete(k, v)}
              deleteIcon={<IconClear />}
              variant="default"
            />
          </Grid>
        )),
      )}
      <Grid item align="center">
        <span className="link" onClick={onClearAll}>
          {t('Clear all')}
        </span>
      </Grid>
    </Grid>
  );
});

ActiveFilters.propTypes = {
  filters: PropType.shape({
    materialType: PropType.objectOf(Map),
    colors: PropType.objectOf(Map),
    industries: PropType.objectOf(Map),
    manufacturer: PropType.objectOf(Map),
    tags: PropType.objectOf(Map),
    uploadedScope: PropType.objectOf(Map),
  }).isRequired,
  activeFilters: PropType.shape({
    materialType: PropType.object,
    colors: PropType.object,
    industries: PropType.object,
    manufacturer: PropType.object,
    tags: PropType.object,
    uploadedScope: PropType.object,
  }).isRequired,
  onDelete: PropType.func.isRequired,
  onClearAll: PropType.func.isRequired,
};

export default withTranslation()(Filters);
