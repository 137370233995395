import { put, call, takeEvery } from 'redux-saga/effects';
import { Api } from '../Utils/Api';

export function* auth(callback) {
  try {
    const sessionData = yield call(Api.fetch, '', { host: window.SSO_SESSION });
    yield put({ type: 'GET_SESSION#COMPLETE', data: sessionData });

    if (sessionData.UserID === 0) {
      window.location = `${window.SSO_FRONTEND}/service_login?return_to=${encodeURIComponent(window.location.href)}`;
    }

    if (callback instanceof Function) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: 'GET_SESSION#ERROR', error });
  }
}

export function* watchRouteChange() {
  yield takeEvery('router/NAVIGATE', (route) => {
    // handle BUILDS custom case for page scrolling
    if (
      (route.id === 'BUILDS' && !route.query) ||
      (route.id === 'BUILDS' && Object.keys(route.query).length <= 2) ||
      route.id === 'VRSCAN'
    ) {
      window.scroll(0, 0);
    }
    // handle VRSCANS custom case for page scrolling
    if (route.id === 'VRSCANS') {
      if (!route.query) {
        window.scroll(0, 0);
      } else {
        window.scroll(0, 280);
      }
    }
  });
}
