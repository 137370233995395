import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import DownloadButton from './DownloadButton';
import Link from '../Helper/Link';
import { strings } from '../Utils/strings';

import '../../styles/vrscan.sass';

// eslint-disable-next-line complexity
function Vrscan({ vrscan, t }) {
  const [modalOpen, setModalOpen] = useState(false);
  if (vrscan.error) {
    return (
      <div className="page">
        <p className="error">{vrscan.error.message}</p>
      </div>
    );
  }

  if (vrscan.loading) {
    return (
      <div className="page">
        <p className="loader">LOADING...</p>
      </div>
    );
  }

  if (!vrscan || !vrscan.id) {
    return (
      <div className="page">
        <p className="error">{t('Vrscan not found')}</p>
      </div>
    );
  }

  return (
    <div className="page">
      <Dialog open={modalOpen} maxWidth="xl" aria-labelledby="dialog-title">
        <div className="vrscan-popup">
          <DialogTitle id="dialog-title" className="title">
            {vrscan.name}
            <CloseIcon className="close-icon" onClick={() => setModalOpen(false)} />
          </DialogTitle>

          <p className="details">
            <span className="link">{t('Manufacturer')}: </span>
            <Link
              className="manufacturer"
              action="VRSCANS"
              queryParams={{ query: { manufacturer: vrscan.manufacturer.id } }}
            >
              {vrscan.manufacturer.name}
            </Link>

            <Link className="link" action="VRSCANS" queryParams={{ query: { material_type: vrscan.materialType.id } }}>
              {vrscan.materialType.name}
            </Link>

            {vrscan.colors.map((c) => (
              <Link key={c.id} className="link" action="VRSCANS" queryParams={{ query: { colors: c.id } }}>
                {c.name}
              </Link>
            ))}
          </p>
          <DialogContent className="image-preview">
            <img
              className="large"
              alt={vrscan.name}
              src={`/images/vrscans/${vrscan.fileName.replace('.vrscan', '')}/${vrscan.fileName.replace(
                '.vrscan',
                '.jpg',
              )}`}
            />
            {vrscan.manufacturer && vrscan.manufacturer.logo_preview_file_name && (
              <img
                // eslint-disable-next-line max-len
                src={`/upload/manufacturers/logo_previews/${vrscan.manufacturer.id}/original/${vrscan.manufacturer.logo_preview_file_name}`}
                className="man"
                alt={vrscan.name}
              />
            )}
          </DialogContent>
        </div>
      </Dialog>

      <Grid container justify="center">
        <img src="/images/Scans_Logo_Colour-Black_RGB.svg" height="50px" className="vrscan-logo" alt="vrscan logo" />
      </Grid>
      <Grid container className="container vrscan-item" spacing={3}>
        <Grid item md={6} className="image">
          <div className="image-container" onClick={() => setModalOpen(true)}>
            <div className="overlay" />
            <img src={`/images/vrscans/large/${vrscan.fileName.replace('.vrscan', '')}`} alt={vrscan.name} />
          </div>
        </Grid>

        <Grid item md={6}>
          <div className="vrscan-description">
            {vrscan.manufacturer && vrscan.manufacturer.logo_file_name && (
              <div>
                <img
                  // eslint-disable-next-line max-len
                  src={`/upload/manufacturers/logos/${vrscan.manufacturer.id}/original/${vrscan.manufacturer.logo_file_name}`}
                  alt={vrscan.name}
                />
              </div>
            )}

            <h4 className="vrscan-name">{vrscan.name}</h4>
            <div className="row">
              <span className="label">{t('Material type')}:</span>
              <span className="description">{vrscan.materialType.name}</span>
            </div>

            <div className="row">
              <span className="label">{t('Material color')}:</span>
              <span className="description">{vrscan.colors.map((c) => (c ? c.name : null)).join(', ')}</span>
            </div>

            <div className="row">
              <span className="label">{t('Suitable for')}:</span>
              <span className="description">{vrscan.industries.map((i) => (i ? i.name : null)).join(', ')}</span>
            </div>

            {vrscan.manufacturer && vrscan.manufacturer.name && (
              <div className="row">
                <span className="label">{t('Manufacturer')}:</span>
                <span className="description">{vrscan.manufacturer.name}</span>
              </div>
            )}

            {vrscan.manufacturer && vrscan.manufacturer.website && vrscan.manufacturer.website !== '' && (
              <div className="row">
                <span className="label">{t('Manufacturer website')}:</span>
                <a className="description manufacturer-website" href={vrscan.manufacturer.website}>
                  {vrscan.manufacturer.website}
                </a>
              </div>
            )}

            <div className="row">
              <span className="label">{t('Tags')}</span>
              <span className="description">{vrscan.tags.map((tag) => (tag ? tag.name : null)).join(', ')}</span>
            </div>

            <div className="row">
              <span className="label">{t('File name')}:</span>
              <span className="description">{vrscan.fileName}</span>
            </div>

            <div className="row">
              <span className="label">{t('File size')}:</span>
              <span className="description">{strings.toHumanFileSize(vrscan.materialFileSize)}</span>
            </div>

            <p>
              {t(
                // eslint-disable-next-line max-len
                'Note: Due to manufacturing process and use of natural materials, final color may vary. Please reach out to the manufacturer for more details.',
              )}
            </p>

            <DownloadButton className="button-round" vrscan={vrscan}>
              {t('Download')}
            </DownloadButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default connect((state) => ({ vrscan: state.vrscan }))(withTranslation()(Vrscan));
