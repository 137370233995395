function userAgentHas(str) {
  return window.navigator.userAgent.indexOf(str) > -1;
}
function userAgentHasIn(...args) {
  return args.some(userAgentHas);
}

function appVersionIs(str) {
  return navigator.appVersion.indexOf(str) !== -1;
}

function platformIs(platform) {
  return platform === window.navigator.platform;
}

function platformIsIn(...args) {
  return args.some(platformIs);
}

function getArchitecture() {
  if (
    userAgentHasIn('x86_64', 'x86-64', 'Win64', 'x64;', 'amd64', 'AMD64', 'WOW64', 'x64_64') ||
    platformIsIn('MacIntel', 'Linux x86_64')
  ) {
    return 'x64';
  }
  if (platformIsIn('Linux armv7l', 'iPad', 'iPhone', 'Android', 'iPod', 'BlackBerry')) {
    return 'mobile';
  }
  if (platformIs('Linux i686')) {
    return '';
  }

  return 'x32';
}

function getOS() {
  if (appVersionIs('Win')) {
    return 'Windows';
  }
  if (appVersionIs('Mac')) {
    return 'Mac OS';
  }
  if (appVersionIs('Linux') || userAgentHas('Linux')) {
    return 'Linux';
  }
  return '';
}

export function detect() {
  return {
    os: getOS(),
    architecture: getArchitecture(),
  };
}
