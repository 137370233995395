import { en } from './en';
import { es } from './es';
import { pt } from './pt';
import { cn } from './cn';
import { kr } from './kr';

export const translations = {
  en,
  es,
  pt,
  cn,
  kr,
};