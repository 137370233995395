const { productFamilies } = window;
const { platforms } = window;
export const buildTiles = [
  {
    title: 'anima ALL',
    image: 'anima.svg',
    filter: { product: productFamilies.Anima.id, platform: platforms.Standalone.id },
  },
  {
    title: 'Enscape',
    image: 'enscape.svg',
    filter: { product: productFamilies.Enscape.id, platform: platforms.Plugin.id },
  },
  {
    title: 'Enscape Preview',
    image: 'enscape.svg',
    filter: { product: productFamilies.EnscapePreview.id, platform: platforms.Plugin.id },
  },
  {
    title: 'V-Ray for Sketchup',
    image: 'v-ray-for-sketchup.svg',
    filter: { product: productFamilies.VRay.id, platform: platforms.SketchUp.id },
  },
  {
    title: 'V-Ray for 3ds Max',
    image: 'v-ray-for-3ds-max.svg',
    filter: { product: productFamilies.VRay.id, platform: platforms['3dsMax'].id },
  },
  {
    title: 'V-Ray for Maya',
    image: 'v-ray-for-maya.svg',
    filter: { product: productFamilies.VRay.id, platform: platforms.Maya.id },
  },
  {
    title: 'V-Ray for Rhino',
    image: 'v-ray-for-rhino.svg',
    filter: { product: productFamilies.VRay.id, platform: platforms.Rhino.id },
  },
  {
    title: 'V-Ray for Revit',
    image: 'v-ray-for-revit.svg',
    filter: { product: productFamilies.VRay.id, platform: platforms.Revit.id },
  },
  {
    title: 'Phoenix for 3ds Max',
    image: 'phoenix-3ds-max.svg',
    filter: { product: productFamilies.Phoenix.id, platform: platforms['3dsMax'].id },
  },
  {
    title: 'Phoenix for Maya',
    image: 'phoenix-maya.svg',
    filter: { product: productFamilies.Phoenix.id, platform: platforms.Maya.id },
  },
  {
    title: 'Chaos License Server',
    image: 'license-server.svg',
    filter: { product: productFamilies.LicenseServer.id, platform: platforms.Standalone.id },
  },
  {
    title: 'Chaos Vantage',
    image: 'chaosvantage.svg',
    filter: { product: productFamilies.ChaosVantage.id, platform: platforms.Standalone.id },
  },
  {
    title: 'V-Ray for Unreal',
    image: 'v-ray-for-unreal.svg',
    filter: { product: productFamilies.VRay.id, platform: platforms.Unreal.id },
  },
  {
    title: 'V-Ray for Cinema 4D',
    image: 'v-ray-for-cinema-4d.svg',
    filter: { product: productFamilies.VRay.id, platform: platforms.Cinema4d.id },
  },
  {
    title: 'V-Ray for NUKE',
    image: 'v-ray-for-nuke.svg',
    filter: { product: productFamilies.VRay.id, platform: platforms.Nuke.id },
  },
  {
    title: 'V-Ray Standalone',
    image: 'v-ray-standalone.svg',
    filter: { product: productFamilies.VRay.id, platform: platforms.Standalone.id },
  },
  {
    title: 'V-Ray AppSDK',
    image: 'v-ray-app-sdk.svg',
    filter: { product: productFamilies.VRayAppSdk.id, platform: platforms.Standalone.id },
  },
  {
    title: 'V-Ray Benchmark',
    image: 'v-ray-for-benchmark.svg',
    filter: { product: productFamilies.VRayBenchmark.id, platform: platforms.Standalone.id },
  },
  {
    title: 'V-Ray for Houdini',
    image: 'v-ray-for-houdini.svg',
    filter: { product: productFamilies.VRay.id, platform: platforms.Houdini.id },
  },
  {
    title: 'PDPlayer',
    image: 'pdplayer.svg',
    filter: { product: productFamilies.Pdplayer.id, platform: platforms.Standalone.id },
  },
  {
    title: 'Chaos Player',
    image: 'chaos-player.svg',
    filter: { product: productFamilies.ChaosPlayer.id, platform: platforms.Standalone.id },
  },
  {
    title: 'V-Ray Swarm',
    image: 'v-ray-swarm.svg',
    filter: { product: productFamilies.VRaySwarm.id, platform: platforms.Standalone.id },
  },
];
