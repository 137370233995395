import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Moment from 'react-moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { EnscapeModal } from '../Utils/EnscapeModal';
import { buildTiles } from './buildTiles';
import {
  productImage,
  platformImage,
  osImage,
  RELEASE_TYPES,
  defaultBuild,
  buildSlug,
  generateBuildDownloadId,
} from '../Utils/Builds';
import Link from '../Helper/Link';
import Spinner from '../Layout/Spinner';
import { navigateWithLanguage } from '../../sagas';
import { detect } from '../Utils/Detect';

// eslint-disable-next-line complexity
function Builds({
  buildsGroups,
  buildsLoading,
  filter,
  filterOptions,
  onBuildClick,
  onPlatformClick,
  onClickBack,
  onDetailsClick,
  t,
}) {
  const [showAll, setShowAll] = useState(false);
  const [showAllVersions, setShowAllVersions] = useState(false);
  const [enscapeModalProps, setEnscapeModalProps] = useState(null);

  React.useEffect(() => {
    setShowAllVersions(false);

    // Show old builds if no new build is for user's OS (Cosmos only)
    const buildGroupNames = Object.keys(buildsGroups);
    const isCosmos = buildGroupNames.some((name) => name.includes('Cosmos'));
    if (buildGroupNames.length === 0 || !isCosmos) {
      return;
    }

    const latestBuild = Object.values(buildsGroups).find((group) => group.isNew);
    const defaultBuildOS = defaultBuild(latestBuild.items).os.name;
    const detectedOS = detect().os;

    if (defaultBuildOS !== detectedOS) {
      setShowAllVersions(true);
    }
  }, [buildsGroups]);

  const hasOldVersions = Object.keys(buildsGroups).some(
    (i) =>
      !buildsGroups[i].isNew ||
      (buildsGroups[i].name.toLowerCase().includes('zip') &&
        buildsGroups[i].platform &&
        buildsGroups[i].platform.name.toLowerCase() !== 'houdini'),
  );

  if (buildsLoading) {
    return <Spinner />;
  }

  const productTile = buildTiles.find((tile) => {
    const { product, platform } = tile.filter;
    return product === Number(filter.product) && platform === Number(filter.platform);
  });
  const productName = productTile ? productTile.title : null;

  return (
    <div className="page">
      {!showPlatforms(filter) && !showBuilds(buildsGroups, filter) && (
        <>
          <h5 className="builds-title">{t('Select your product.')}</h5>
          <Grid container spacing={3} className="build-tiles">
            {buildTiles
              .filter((tile) => tile.title !== 'Enscape Preview')
              .map((item, i) => {
                if (!showAll && i > 7) {
                  return null;
                }
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item xs={12} sm={6} md={3} key={i}>
                    <div
                      className="tile item-border item-border-hover"
                      onClick={() => onBuildClick(item.filter)}
                      style={{
                        backgroundImage: `url(/images/builds/listing/${item.image})`,
                      }}
                    />
                  </Grid>
                );
              })}
          </Grid>
          {!showAll && (
            <div className="showall">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setShowAll(true);
                }}
              >
                {t('See all products')} &gt;
              </a>
            </div>
          )}
        </>
      )}

      {showPlatforms(filter) && !showBuilds(buildsGroups, filter) && (
        <Grid container spacing={3} className="build-tiles">
          <Grid item xs={12} sm={12} md={12}>
            <a href="/" onClick={onClickBack}>
              &lt; {t('Back to all products')}
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="logo">
              <img src={pImage(productTile)} alt="" height="180" />
            </div>
            <div style={{ textAlign: 'center' }}>
              <h5 className="platforms-title">
                {/* V-Ray AppSDK is a standalone product and does not need a platform to work */}
                {productName === 'V-Ray AppSDK'
                  ? t('Select the version.')
                  : t('Select the version of your host platform.')}
              </h5>
            </div>
          </Grid>
          {filterOptions.platformVersions.map((item) => (
            <Grid item xs={12} sm={6} md={3} key={item.key}>
              <div
                className="tile platform item-border item-border-hover"
                onClick={() =>
                  onPlatformClick({
                    platform_version: item.key,
                    license_type: 'adv',
                  })
                }
              >
                <span>{item.value}</span>
              </div>
            </Grid>
          ))}
        </Grid>
      )}

      {showBuilds(buildsGroups, filter) && (
        <Grid container spacing={3} className="build-tiles">
          <Grid item xs={12} sm={12} md={12}>
            <a href="/" onClick={onClickBack}>
              &lt; {t('Back to all products')}
            </a>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="logo">
              <img src={pImage(productTile)} alt="" height="180" />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className="builds-list">
            <div className="found">
              &nbsp;
              {hasOldVersions && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAllVersions}
                      value="1"
                      onChange={(_, checked) => setShowAllVersions(checked)}
                    />
                  }
                  label={t('Show all versions')}
                  style={{ float: 'right', marginTop: -5 }}
                />
              )}
            </div>
            {enscapeModalProps && (
              <EnscapeModal
                setEnscapeModalProps={enscapeModalProps.setEnscapeModalProps}
                downloadButtonId={enscapeModalProps.downloadButtonId}
                id={enscapeModalProps.id}
                t={enscapeModalProps.t}
              />
            )}
            {Object.keys(buildsGroups).map((key) => {
              const b = buildsGroups[key];
              const dBuild = defaultBuild(b.items);
              // Hide older versions and zip builds except for Houdini (all Houdini builds are .zip).
              if (
                !showAllVersions &&
                (!b.isNew ||
                  (dBuild.buildFile.includes('.zip') &&
                    dBuild.platform &&
                    dBuild.platform.name.toLowerCase() !== 'houdini'))
              ) {
                return null;
              }

              const { id, product, platform, version } = dBuild;
              const downloadButtonId = generateBuildDownloadId(product, platform, version);
              const isEnscape41 =
                [window.productFamilies.Enscape.id, window.productFamilies.EnscapePreview.id].includes(product.id) &&
                version.split('.').map((x) => parseInt(x, 10)) >= [4, 1];

              return (
                <Grid
                  container
                  justify="space-between"
                  className="item item-border item-border-hover"
                  key={b.id}
                  onClick={() => onDetailsClick(b.id, buildSlug(b.items[0]))}
                >
                  <Grid item lg={5}>
                    <Grid container>
                      <img className="product-logo" src={productImage(b.items[0])} alt="" />
                      <Grid item>
                        <h4>{b.items[0].groupName}</h4>
                        <h6>
                          {RELEASE_TYPES[b.releaseType]} <span className="dot-separator">&middot;</span>{' '}
                          <Moment date={new Date(b.releaseDate)} format="MMM D, YYYY" />
                        </h6>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={3} className="platform">
                    {showPlatformLogo(b.items[0]) && <img src={platformImage(b.items[0])} alt="" />}
                    <span>{`${b.platform.name} ${b.platformVersionName}`}</span>
                  </Grid>
                  {dBuild.productID !== window.productFamilies.EnscapePreview.id && (
                    <Grid item lg={4} className="dl">
                      <a
                        href={`${window.API}/builds/${id}/download`}
                        className="btn"
                        id={isEnscape41 ? undefined : downloadButtonId}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (isEnscape41) {
                            e.preventDefault();
                            setEnscapeModalProps({ setEnscapeModalProps, downloadButtonId, id, t });
                          }
                        }}
                      >
                        <img src={osImage(dBuild)} height="16" alt="" />
                        {t('Download')}
                      </a>
                      {b.items.length > 1 && (
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            onDetailsClick(b.id, buildSlug(b.items[0]));
                          }}
                        >
                          {t('Download for another OS')}
                        </Link>
                      )}
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </div>
  );
}

const pImage = (tile) => (tile ? `/images/builds/${tile.image}` : '');

const showPlatforms = (filter) => {
  return filter.product && filter.platform;
};

const showPlatformLogo = (build) => {
  if (build.productID === window.productFamilies.VRay.id) {
    return true;
  }

  return ![window.platforms.Plugin.id, window.platforms.Standalone.id].includes(build.platformID);
};

const showBuilds = (buildsGroups, filter) => {
  return Boolean(Object.keys(buildsGroups).length > 0 && filter.product && filter.platform);
};

export default connect(
  (state) => ({
    buildsLoading: state.builds.loading,
    buildsGroups: state.builds.buildsGroups,
    filterOptions: state.builds.filterOptions,
    filter: state.builds.filter,
  }),
  (dispatch) => ({
    onBuildClick: (params) => {
      dispatch({ type: 'BUILDS#FILTER#OPTIONS#CLEAR' });
      dispatch({ type: 'BUILDS#FILTER#SET', data: params });
    },
    onPlatformClick: (params) => {
      dispatch({ type: 'BUILDS#GENERATE#CLEAR' });
      dispatch({ type: 'BUILDS#FILTER#ADD', data: params });
    },
    onClickBack: (e) => {
      e.preventDefault();
      dispatch(navigateWithLanguage('BUILDS'));
      dispatch({ type: 'BUILDS#FILTER#CLEAR' });
      dispatch({ type: 'BUILDS#GENERATE#CLEAR' });
    },
    onDetailsClick: (id, slug) => dispatch(navigateWithLanguage('BUILD_DETAILS', { id, slug })),
  }),
)(withTranslation()(Builds));
