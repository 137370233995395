export const en = {
  translation: {
    'Select your product.': 'Select your product.',
    'Select the version.': 'Select the version.',
    'Select the version of your host platform.': 'Select the version of your host platform.',
    Download: 'Download',
    Downloads: 'Downloads',
    'Back to all products': 'Back to all products',
    'Use the filter to find your product': 'Use the filter to find your product',
    'See all products': 'See all products',
    'Download for another OS': 'Download for another OS',
    'Download for': 'Download for',
    'Show all versions': 'Show all versions',
    'All fields are required': 'All fields are required',
    'Platform version': 'Platform version',
    'License type': 'License type',
    'Please select': 'Please select',
    Search: 'Search',
    '1 item found': '1 item found',
    '{n} items found': '{{n}} items found',
    'Release date': 'Release date',
    'File Size': 'File Size',
    'File size': 'File size',
    "What's new": "What's new",
    'Requirements & changelog': 'Requirements & changelog',
    items: 'items',
    new: 'new',
    'Uploaded before': 'Uploaded before',
    'Material type': 'Material type',
    'Material color': 'Material color',
    'Suitable for': 'Suitable for',
    Manufacturer: 'Manufacturer',
    Manufacturers: 'Manufacturers',
    Tags: 'Tags',
    'Clear all': 'Clear all',
    'File name': 'File name',
    'Note: Due to manufacturing process and use of natural materials, final color may vary. Please reach out to the manufacturer for more details.':
      'Note: Due to manufacturing process and use of natural materials, final color may vary. Please reach out to the manufacturer for more details.',
    'Search Chaos Scans': 'Search Chaos Scans',
    'show all results': 'show all results',
    'No results found.': 'No results found.',

    // Filters
    '1 Week': '1 Week',
    '1 Month': '1 Month',
    '6 Months': '6 Months',

    'Car Paint': 'Car Paint',
    Carbon: 'Carbon',
    Ceramic: 'Ceramic',
    Fabric: 'Fabric',
    Foam: 'Foam',
    Foil: 'Foil',
    Hologram: 'Hologram',
    Leather: 'Leather',
    Metal: 'Metal',
    Paint: 'Paint',
    Paper: 'Paper',
    Plastic: 'Plastic',
    Rubber: 'Rubber',
    Wood: 'Wood',

    Automotive: 'Automotive',
    'Interior Design': 'Interior Design',
    'Product Design': 'Product Design',
    Aerospace: 'Aerospace',

    Generic: 'Generic',
    'Townsend Leather Company, Inc.': 'Townsend Leather Company, Inc.',
    'Handy Living': 'Handy Living',
    'Kawashima Selkon Textiles Co., Ltd.': 'Kawashima Selkon Textiles Co., Ltd.',
    'Nihon Etching Co., Ltd.': 'Nihon Etching Co., Ltd.',
    'Tanazawa Hakkosha Co., Ltd': 'Tanazawa Hakkosha Co., Ltd',

    Alcantara: 'Alcantara',
    Aluminum: 'Aluminum',
    Brass: 'Brass',
    Brushed: 'Brushed',
    Copper: 'Copper',
    Crocodile: 'Crocodile',
    Galvanized: 'Galvanized',
    Glossy: 'Glossy',
    Gold: 'Gold',
    Matte: 'Matte',
    Mirror: 'Mirror',
    Pattern: 'Pattern',
    Perforated: 'Perforated',
    Rust: 'Rust',
    Scratched: 'Scratched',
    Tin: 'Tin',
    Volumetric: 'Volumetric',
    'V-Ray Next Recommended': 'V-Ray Next Recommended',
    Wool: 'Wool',
    Retroreflective: 'Retroreflective',
    Chameleon: 'Chameleon',
    Grain: 'Grain',
    Flat: 'Flat',
    Smooth: 'Smooth',
    Translucent: 'Translucent',

    // Colors
    White: 'White',
    Yellow: 'Yellow',
    Beige: 'Beige',
    Orange: 'Orange',
    Fuchsia: 'Fuchsia',
    Red: 'Red',
    Pink: 'Pink',
    Silver: 'Silver',
    Gray: 'Gray',
    Olive: 'Olive',
    Purple: 'Purple',
    Maroon: 'Maroon',
    Aqua: 'Aqua',
    Lime: 'Lime',
    Teal: 'Teal',
    Green: 'Green',
    Blue: 'Blue',
    Navy: 'Navy',
    Brown: 'Brown',
    Black: 'Black',
  },
};
