export const LANGUAGE_COOKIE = '_cgloc';

export const getCookie = (cName) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cName}=`);

  return parts.length === 2 ? parts.pop().split(';').shift() : '';
};

export const setCookie = (cName, value, domain) => {
  document.cookie = `${cName}=${value};domain=${domain};path=/`;
};
