/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Pagination from 'material-ui-flat-pagination';
import { withStyles } from '@material-ui/core/styles';
import Link from '../Helper/Link';
import SearchBox from '../Layout/SearchBox';
import DownloadButton from './DownloadButton';
import Filters, { ActiveFilters } from './Filters';
import { PER_PAGE as vrscansPerPage } from './vrscansSagas';
import { navigateWithLanguage } from '../../sagas';

import '../../styles/vrscan.sass';

function Vrscans(props) {
  if (props.vrscans.error) {
    return (
      <div className="page">
        <p className="error">{props.vrscans.error.message}</p>
      </div>
    );
  }

  if (props.vrscans.loading) {
    return (
      <div className="page">
        <p className="loader">LOADING...</p>
      </div>
    );
  }

  return (
    <div className="page">
      <Grid container className="container vrscans">
        <Grid container justify="center">
          <img src="/images/Scans_Logo_Colour-Black_RGB.svg" className="vrscan-logo" height="50px" alt="vrscan logo" />
        </Grid>
        <Grid className="item-border filters-container" item md={3} xs={12}>
          <SearchBox
            searchIndex={props.vrscans.searchIndex}
            onSelectResult={(r) => props.navigateVrscan(r.id, r.slug)}
            onSearch={(query) => props.search(query)}
          />
          <Filters
            filters={props.vrscans.filters}
            activeFilters={props.vrscans.activeFilters}
            onChange={props.handleFilterChange}
          />
        </Grid>

        <Grid item md={9} xs={12} className="vrscans-container">
          <ActiveFilters
            filters={props.vrscans.filters}
            activeFilters={props.vrscans.activeFilters}
            onDelete={(filterKey, filterValue) => props.handleFilterChange(filterKey, filterValue, false)}
            onClearAll={props.handleFiltersClear}
          />
          <Grid container className="vrscans-grid" spacing={4}>
            {props.vrscans.filtered
              .slice()
              .splice(props.vrscans.offset, vrscansPerPage)
              .map((vrscan, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <VrscanGridItem vrscan={vrscan} t={props.t} key={i} />
              ))}
          </Grid>
          <VrscansPagination className="pagination footer" {...props} />
        </Grid>
      </Grid>
    </div>
  );
}

function VrscanGridItem({ vrscan, t }) {
  return (
    <Grid item md={4} xs={12} className="vrscan" align="left">
      <div className="item item-border item-border-hover">
        {isNewVrscan(vrscan.createdAt) && (
          <div className="ribbon-wrapper">
            <div className="ribbon">
              <span className="label">{t('new')}</span>
            </div>
          </div>
        )}

        <img src={`/images/vrscans/thumb/${vrscan.fileName.split('.')[0]}`} alt={vrscan.name} />
        {vrscan.manufacturer && vrscan.manufacturer.logo_small_file_name && (
          <img
            src={`/upload/manufacturers/logo_smalls/${vrscan.manufacturer.id}/original/${vrscan.manufacturer.logo_small_file_name}`}
            className="small-man-logo"
            alt={vrscan.name}
          />
        )}
        <Link
          action="VRSCAN"
          params={{
            id: vrscan.id,
            name: vrscan.fileName.replace('.vrscan', ''),
          }}
          className="image-link"
        >
          {vrscan.name}
        </Link>

        <div className="info-box">
          <h5 className="item-title">{vrscan.name}</h5>
          <p className="item-summary manufacturer-name">{vrscan.manufacturer.name}</p>
          <p className="item-summary file-name">{vrscan.fileName}</p>
        </div>
        <DownloadButton vrscan={vrscan}>{t('Download')}</DownloadButton>
      </div>
    </Grid>
  );
}

function VrscansPagination(props) {
  return (
    <Grid container className={props.className} spacing={4}>
      <Grid item xs={12} className="summary" align="center">
        <span className="number">
          {props.vrscans.filtered.length} {props.t('items')}
        </span>
        <span className="current-page">
          [ {props.vrscans.offset + 1} - {props.vrscans.offset + vrscansPerPage} ]
        </span>
      </Grid>

      <Grid item xs={12} align="center">
        {props.vrscans.filtered.length > vrscansPerPage && (
          <StyledPagination
            limit={vrscansPerPage}
            offset={props.vrscans.offset}
            total={props.vrscans.filtered.length}
            onClick={(_, offset) => props.navigatePage(offset)}
          />
        )}
      </Grid>
    </Grid>
  );
}

const StyledPagination = withStyles({
  text: {
    color: '#1C1C1C',
    minWidth: 36,
    minHeight: 36,
    borderRadius: 999,
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
  },
  rootCurrent: {
    border: '1px solid #EDEDED',
    color: '#0DB5FF',
  },
})(Pagination);

function isNewVrscan(createdAt) {
  const timeDiff = Date.now() - new Date(createdAt).getTime();
  const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));

  return daysDiff < 60;
}

export default connect(
  (state) => ({
    vrscans: state.vrscans,
  }),
  (dispatch) => ({
    handleFilterChange: (filterType, id, value) => dispatch({ type: 'VRSCANS#FILTER_CHANGE', filterType, id, value }),
    handleFiltersClear: () => dispatch({ type: 'VRSCANS#FILTERS_CLEAR_SELECTED' }),
    navigatePage: (offset) => dispatch({ type: 'VRSCANS#NAVIGATE_PAGE', data: { offset } }),
    navigateVrscan: (id, scanName) => dispatch(navigateWithLanguage('VRSCAN', { id, name: scanName })),
    search: (query) => dispatch({ type: 'VRSCANS#HANDLE_QUERY_CHANGE', query }),
  }),
)(withTranslation()(Vrscans));
