import React from 'react';
import { connect } from 'react-redux';

import './index.sass';

/* eslint-disable camelcase */ // props background_color and icon_url
function GlobalNotice({ text, button, theme, background_color, icon_url }) {
  const showGlobalNotice = Boolean(text);
  const { label, link, type, id, rel, target } = button;
  return (
    showGlobalNotice && (
      <div id="global-notice" className={theme} style={{ backgroundColor: background_color }}>
        {icon_url && <img alt="chaos" src={icon_url} />}
        <span className="global-notice-text">
          {text}
          {link && (
            <a className={`global-notice-button ${type}`} href={link} id={id} rel={rel} target={target}>
              {label}
            </a>
          )}
        </span>
      </div>
    )
  );
}

export default connect((state) => state.globalNotice)(GlobalNotice);
