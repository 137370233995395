const initialState = {
  items: [],
  filteredByQuery: [],
  filtered: [],
  filters: {
    materialType: new Map(),
    colors: new Map(),
    industries: new Map(),
    manufacturer: new Map(),
    tags: new Map(),
    uploadedScope: new Map(),
  },
  activeFilters: {},
  offset: 0,
  loading: false,
  error: null,
};

const vrscanInitialState = {
  colors: [],
  fileName: '',
  id: NaN,
  industries: [],
  manufacturer: {},
  manufacturerId: NaN,
  materialFileSize: NaN,
  materialType: {},
  materialTypeId: NaN,
  name: '',
  tags: [],
  loading: true,
  error: null,
};

// eslint-disable-next-line complexity
export function vrscans(state = initialState, payload) {
  switch (payload.type) {
    case 'GET_VRSCANS#START':
      return { ...state, loading: true };
    case 'GET_VRSCANS#COMPLETE':
      return { ...state, ...payload.data, loading: false };
    case 'VRSCANS#SET_FILTERED':
      return {
        ...state,
        ...payload.data,
        offset: payload.data.offset || 0,
      };
    case 'GET_VRSCANS#ERROR':
      return { ...state, error: payload.data.error };
    case 'VRSCANS#SET_OFFSET':
      return { ...state, offset: payload.data.offset };
    case 'VRSCANS#FILTERS_CLEAR_SELECTED':
      return { ...state, activeFilters: {} };
    case 'VRSCANS#RESET':
      return { ...initialState };
    default:
      return state;
  }
}

export function vrscan(state = vrscanInitialState, payload) {
  switch (payload.type) {
    case 'GET_VRSCAN#START':
      return { ...state, loading: true };
    case 'GET_VRSCAN#COMPLETE':
      return { ...state, ...payload.data, loading: false };
    case 'GET_VRSCAN#ERROR':
      return { ...state, error: payload.data.error, loading: false };
    case 'VRSCAN#RESET':
      return { ...vrscanInitialState };
    default:
      return state;
  }
}
