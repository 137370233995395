/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import { largeProductImage, fullTitle, RELEASE_TYPES } from '../Utils/Builds';

function BuildsDetails({ builds, routing }) {
  if (builds.length === 0) {
    return null;
  }
  const build = builds.find((b) => b.id === parseInt(routing.params.id, 10));

  return (
    <div className="page build-details">
      <div className="logo">
        <img src={largeProductImage(builds[0])} alt="" height="90" />
      </div>
      <h1>{fullTitle(builds[0])}</h1>
      <h2>{RELEASE_TYPES[builds[0].releaseType]}</h2>
      <div className="changelog">
        <h4>System requirements</h4>
        {build.buildInfo.systemRequirements.URL ? (
          <div>
            <a href={build.buildInfo.systemRequirements.URL}>View more</a>
          </div>
        ) : (
          <ul>
            <li>
              Minimum requirements
              <ul>{build.buildInfo.systemRequirements && insertLinks(build.buildInfo.systemRequirements.minimum)}</ul>
            </li>
            <li>
              Recommended requirements
              <ul>
                {build.buildInfo.systemRequirements && insertLinks(build.buildInfo.systemRequirements.recommended)}
              </ul>
            </li>
          </ul>
        )}
        <h4>CHANGELOG</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: build.buildInfo.changeLog.replace(/\n/g, '<br/>'),
          }}
        />
      </div>
    </div>
  );
}

const urlRegex =
  /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-.@:%_+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/;

function insertLinks(list) {
  return Object.keys(list).map((i) => <li key={i}>{urlRegex.test(list[i]) ? <a href={list[i]}>{i}</a> : list[i]}</li>);
}

export default connect((state) => ({
  builds: state.buildDetails.builds,
  routing: state.routing,
}))(BuildsDetails);
