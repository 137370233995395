import createSagaMiddleware from 'redux-saga';
import { take, takeEvery, fork, put, select, call } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';
import i18n from 'i18next';
import { auth } from './app/App/appSagas';
import { getCookie, setCookie, LANGUAGE_COOKIE } from './app/Utils/cookiesHelper';
import globalNoticeRootSaga from './app/Layout/GlobalNotice/globalNoticeSagas';

export function* rootSaga() {
  yield fork(watchNavigateWithLanguage);
  yield fork(watchLangChange);
  yield fork(globalNoticeRootSaga);
  yield call(auth);
  yield call(getLanguage);
}

export const sagaMiddleware = createSagaMiddleware();

// We use call(halt) to indicate that the saga is done,
// but we still dont want to terminate for example
// it needs to perform clean-up when it is canceled
// by a navigation event:
export function* halt() {
  yield take('__NA__');
}

export const languages = ['en', 'es', 'pt', 'cn', 'kr'];

export function* handleLanguage() {
  const route = yield select((state) => state.routing);
  const lg = route.params.lg || 'en';

  window.document.documentElement.setAttribute('lang', lg);
  i18n.changeLanguage(lg);
}

export function navigateWithLanguage(id, params = {}, opts = {}) {
  return { type: 'NAVIGATE_WITH_LANG', id, params, opts };
}

function* getLanguage() {
  const { id, params, query } = yield select((state) => state.routing);

  let language = '';

  // check cg-website cookie
  const cookieLanguage = getCookie(LANGUAGE_COOKIE);
  if (languages.includes(cookieLanguage)) {
    language = cookieLanguage;
  }

  // check URL
  const { lg } = params || {};
  if (lg && languages.includes(lg)) {
    language = lg;
  }

  if (!languages.includes(language) || language === 'en') {
    language = '';
  }

  yield put(navigate(id, { ...params, lg: language || null }, { query }));
}

export function* watchLangChange() {
  yield takeEvery('LANG#CHANGE', onLangChange);
}

export function* watchNavigateWithLanguage() {
  yield takeEvery('NAVIGATE_WITH_LANG', onNavigateWithLanguage);
}

function* onLangChange(payload) {
  const lang = payload.data.toLowerCase();
  setCookie(LANGUAGE_COOKIE, lang, '.chaosgroup.com');

  const route = yield select((state) => state.routing);
  const params = { ...route.params, lg: lang };
  if (lang === 'en') {
    delete params.lg;
  }

  yield put(navigate(route.id, params, { query: route.query }));
}

function* onNavigateWithLanguage(payload) {
  const paramsWithLg = { ...payload.params, lg: i18n.language };
  if (paramsWithLg.lg === 'en') {
    delete paramsWithLg.lg;
  }

  yield put(navigate(payload.id, paramsWithLg, { query: payload.opts.query }));
}
