/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routesMap } from '../../routes';
import { navigateWithLanguage } from '../../sagas';

function Link({ routing, action, children, dispatch, href, params, onClick, queryParams, ...props }) {
  let hrefAttr = href;

  if (action) {
    // eslint-disable-next-line no-param-reassign
    props.onClick = (e) => {
      e.preventDefault();
      if (onClick) {
        onClick();
      }
      dispatch(navigateWithLanguage(action, params, queryParams));
    };

    if (!hrefAttr) {
      const route = routesMap.get(action);
      if (route) {
        hrefAttr = route.toPath(params);
      }
    }
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={hrefAttr} {...props}>
      {children}
    </a>
  );
}

Link.propTypes = {
  action: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  href: PropTypes.string,
  tabIndex: PropTypes.string,
  target: PropTypes.string,
};

Link.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  dispatch: noopWarn,
  params: {},
};

function noopWarn() {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line
    console.warn(`default 'dispatch' called for component <Link />`);
  }
}

export default connect((state) => ({ routing: state.routing }))(Link);
