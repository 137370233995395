import { buildRoutesMap, route } from 'redux-saga-first-router';
import { navigateBuilds, setFilter } from './app/Builds/buildsSagas';
import { navigateBuildDetails } from './app/BuildDetails/buildDetailsSagas';
import { navigateRedirect } from './app/Builds/redirectSagas';
import { navigateVrscan, navigateVrscans, navigateVrscansQuery } from './app/Vrscans/vrscansSagas';
import { productCodeRedirect } from './app/Utils/products';
import { languages } from './sagas';

const lgSegment = `/:lg(${languages.join('|')})?`;

export const routes = {
  BUILDS: {
    path: lgSegment,
    saga: navigateBuilds,
    querySaga: setFilter,
  },
  PRODUCT_CODE: {
    path: `${lgSegment}/products/:code`,
    saga: productCodeRedirect,
  },
  BUILD_DETAILS: {
    path: `${lgSegment}/downloads/:id/:slug`,
    saga: navigateBuildDetails,
  },
  BUILD_CHANGELOG: {
    path: `${lgSegment}/changelog/:id`,
    saga: navigateBuildDetails,
  },
  VRSCANS: {
    path: `${lgSegment}/vrscans`,
    saga: navigateVrscans,
    querySaga: navigateVrscansQuery,
  },
  VRSCAN: {
    path: `${lgSegment}/vrscans/:id/:name`,
    saga: navigateVrscan,
  },
  404: {
    path: '/404',
  },
  500: {
    path: '/500',
  },
  // Backwards compatibility redirects
  R_LS: {
    path: '/license-server',
    saga: navigateRedirect,
  },
  R_COSMOS: {
    path: '/chaos-cosmos',
    saga: navigateRedirect,
  },
  R_PDPLAYER: {
    path: '/pdplayer',
    saga: navigateRedirect,
  },
  R_PHOENIX: {
    path: '/phoenix',
    saga: navigateRedirect,
  },
  R_PHOENIX_MAX: {
    path: '/phoenix/3ds-max',
    saga: navigateRedirect,
  },
  R_PHOENIX_MAYA: {
    path: '/phoenix/maya',
    saga: navigateRedirect,
  },
  R_APP_SDK: {
    path: '/v-ray/app-sdk',
    saga: navigateRedirect,
  },
  R_VRAY_MAX: {
    path: '/v-ray/3ds-max',
    saga: navigateRedirect,
  },
  R_VRAY_MAYA: {
    path: '/v-ray/maya',
    saga: navigateRedirect,
  },
  R_VRAY_NUKE: {
    path: '/v-ray/nuke',
    saga: navigateRedirect,
  },
  R_VRAY_REVIT: {
    path: '/v-ray/revit',
    saga: navigateRedirect,
  },
  R_VRAY_RHINO: {
    path: '/v-ray/rhino',
    saga: navigateRedirect,
  },
  R_VRAY_SKETCHUP: {
    path: '/v-ray/sketchup',
    saga: navigateRedirect,
  },
  R_VRAY_STANDALONE: {
    path: '/v-ray/standalone',
    saga: navigateRedirect,
  },
  R_VRAY_SOFTIMAGE: {
    path: '/v-ray/softimage',
    saga: navigateRedirect,
  },
  R_VRAY_SWARM: {
    path: '/v-ray/swarm',
    saga: navigateRedirect,
  },
};

export const routesMap = buildRoutesMap(
  ...Object.keys(routes).map((routeID) =>
    route(routeID, routes[routeID].path, routes[routeID].saga, routes[routeID].querySaga),
  ),
);
