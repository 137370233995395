/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import Moment from 'react-moment';
import { navigateWithLanguage } from '../../sagas';
import { osImage, largeProductImage, fullTitle, RELEASE_TYPES, generateBuildDownloadId } from '../Utils/Builds';
import { strings } from '../Utils/strings';
import Link from '../Helper/Link';
import Spinner from '../Layout/Spinner';
import { UnstableVersionModal } from './UnstableVersionModal';
import { EnscapeModal } from '../Utils/EnscapeModal';

function BuildsDetails({ builds, t }) {
  const [unstableVersionModalProps, setUnstableVersionModalProps] = useState(null);
  const [enscapeModalProps, setEnscapeModalProps] = useState(null);

  if (builds.length === 0) {
    return <Spinner />;
  }

  return (
    <>
      <div className="page build-details">
        {unstableVersionModalProps && (
          <UnstableVersionModal
            setUnstableVersionModalProps={unstableVersionModalProps.setUnstableVersionModalProps}
            downloadButtonId={unstableVersionModalProps.downloadButtonId}
            id={unstableVersionModalProps.id}
            t={unstableVersionModalProps.t}
          />
        )}
        {enscapeModalProps && (
          <EnscapeModal
            setEnscapeModalProps={enscapeModalProps.setEnscapeModalProps}
            downloadButtonId={enscapeModalProps.downloadButtonId}
            id={enscapeModalProps.id}
            t={enscapeModalProps.t}
          />
        )}
        <a href="/">&lt; {t('Back to all products')}</a>
        <div className="logo">
          <img src={largeProductImage(builds[0])} alt="" height="90" />
        </div>
        <h1>{fullTitle(builds[0])}</h1>
        <h2>{RELEASE_TYPES[builds[0].releaseType]}</h2>
        <Grid container spacing={4}>
          {builds.map((b) => {
            const { os, architecture, releaseDate, fileSize, buildInfo, id, product, platform, version } = b;

            const downloadButtonId = generateBuildDownloadId(product, platform, version);
            const isEnscape41 =
              [window.productFamilies.Enscape.id, window.productFamilies.EnscapePreview.id].includes(product.id) &&
              version.split('.').map((x) => parseInt(x, 10)) >= [4, 1];

            return (
              <Grid className="item item-border item-border-hover" item xs={12} sm={6} md={5} lg={3} key={b.id}>
                <h4>
                  <img src={osImage(b, true)} height="18" alt="" /> for {os.name} {architecture.name}
                </h4>
                <Grid container className="details">
                  <Grid item xs={6}>
                    {t('Release date')}
                  </Grid>
                  <Grid item xs={6} className="right">
                    <Moment date={new Date(releaseDate)} format="MMM D, YYYY" />
                  </Grid>
                </Grid>
                <Grid container className="details">
                  <Grid item xs={6}>
                    {t('File Size')}
                  </Grid>
                  <Grid item xs={6} className="right">
                    {strings.toHumanFileSize(fileSize)}
                  </Grid>
                </Grid>
                <Grid>
                  {buildInfo && (
                    <Link action="BUILD_CHANGELOG" params={{ id }} className="req">
                      {t('Requirements & changelog')}
                    </Link>
                  )}
                </Grid>
                <Grid>
                  <a
                    id={buildInfo.systemRequirements.Warning || isEnscape41 ? undefined : downloadButtonId}
                    href={`${window.API}/builds/${id}/download`}
                    className="dl"
                    onClick={(e) => {
                      if (buildInfo.systemRequirements.Warning) {
                        e.preventDefault();
                        setUnstableVersionModalProps({ setUnstableVersionModalProps, downloadButtonId, id, t });
                      } else if (isEnscape41) {
                        e.preventDefault();
                        setEnscapeModalProps({ setEnscapeModalProps, downloadButtonId, id, t });
                      }
                    }}
                  >
                    {t('Download')}
                  </a>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>
      {builds[0].buildGroup && builds[0].buildGroup.description && (
        <div className="whatsnew">
          <div>
            <h3>{t("What's new")}</h3>
            {!['Enscape', 'Enscape Preview'].includes(builds[0].product.name) ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: builds[0].buildGroup.description,
                }}
              />
            ) : (
              <>
                <div className="description">
                  {builds.map((build) => (
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: build.buildGroup.description,
                        }}
                      />
                      {build.buildInfo.systemRequirements.Warning && (
                        <div className="warning">
                          <img src="/images/attention.svg" alt="attention" />
                          <div>
                            <h4>Unstable version</h4>
                            <p>{build.buildInfo.systemRequirements.Warning}</p>
                          </div>
                        </div>
                      )}
                      <div className="supported">
                        <h4>Supported solutions</h4>
                        {Object.entries(build.buildInfo.systemRequirements['Supported Platforms']).map(
                          ([platform, versions]) => (
                            <div className="card">
                              <div className="platform">
                                <img alt={platform} src={`/images/platforms/${platform.toLowerCase()}.svg`} />
                                {platform}
                              </div>
                              <div className="versions">{versions.join(', ')}</div>
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                {builds[0].product.name === 'Enscape' && (
                  <div className="previews">
                    <h4>Regular previews</h4>
                    <p>
                      In addition to the stable releases, we offer preview releases. Those builds might be unstable, but
                      contain new features, and fixes. Find them <EnscapePreviewLink innerText="here" />.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function EnscapePreviewLink({ innerText }) {
  return (
    <Link href={`/?platform=${window.platforms.Plugin.id}&product=${window.productFamilies.EnscapePreview.id}`}>
      {innerText}
    </Link>
  );
}

export default connect(
  (state) => ({
    builds: state.buildDetails.builds,
  }),
  (dispatch) => ({
    onClickBack: (e) => {
      e.preventDefault();
      dispatch(navigateWithLanguage('BUILDS'));
      dispatch({ type: 'BUILDS#FILTER#CLEAR' });
      dispatch({ type: 'BUILDS#GENERATE#CLEAR' });
    },
  }),
)(withTranslation()(BuildsDetails));
