import React from 'react';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Spinner({ delay = 1000, size = 60 }) {
  return (
    <Fade in style={{ transitionDelay: `${delay}ms` }} className="content-loader">
      <div className="text-center">
        <div>
          <CircularProgress className="primary" size={size} />
        </div>
      </div>
    </Fade>
  );
}
