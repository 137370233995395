import React from 'react';

export function UnstableVersionModal({ setUnstableVersionModalProps, downloadButtonId, t, id }) {
  return (
    <div
      className="UnstableVersionModal"
      onClick={(event) => {
        event.stopPropagation();
        setUnstableVersionModalProps(null);
      }}
    >
      <div className="content" onClick={(event) => event.stopPropagation()}>
        <h4>Unstable version</h4>
        <p>We advise you to not use this version for customer communication, or presentations.</p>
        <a id={downloadButtonId} href={`${window.API}/builds/${id}/download`} className="dl">
          {t('Download anyway')}
        </a>
      </div>
    </div>
  );
}
