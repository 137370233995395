/* eslint-disable react/no-danger */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { blackAndWhite } from '../Utils/themes';

const styles = {
  inputRoot: {
    background: '#EDEDED',
    borderRadius: '4px',
    '& fieldset': {
      border: 'none',
    },
  },
  searchInput: {
    paddingLeft: 44,
    marginBottom: -2,
    '&::before': {
      content: 'none',
    },
    '&::placeholder': {
      color: '#ababab',
      opacity: 1,
    },
  },
  searchResultsPaper: {
    width: '100%',
    position: 'absolute',
    zIndex: 2,
  },
};

function SearchBox({ value, searchIndex, maxResults, minQueryThreshold, onSelectResult, classes, onSearch, t }) {
  const [searchValue, setSearchValue] = useState(value);
  const [active, setActive] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState(-1);
  const maxSelectedResult = results.length > maxResults ? maxResults : results.length;

  return (
    <MuiThemeProvider theme={blackAndWhite}>
      <Grid
        container
        justify="flex-start"
        alignItems="flex-end"
        alignContent="stretch"
        style={{ position: 'relative' }}
      >
        <Grid item style={{ position: 'absolute', left: 15, bottom: 10, zIndex: 2 }}>
          <img src="/images/search.svg" alt="" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder={t('Search Chaos Scans')}
            type="search"
            value={searchValue}
            classes={{
              root: classes.inputRoot,
            }}
            InputProps={{
              classes: { input: classes.searchInput },
            }}
            onFocus={() => {
              if (searchValue.length >= minQueryThreshold) {
                setResults(searchIndex.search(trimQuery(searchValue)));
              }
              setSelectedResult(-1);
            }}
            onBlur={() => {
              setActive(false);
              if (results.length > 0 || searchValue === '') {
                onSearch(searchValue);
              }
            }}
            onChange={(e) => {
              const val = e.target.value;
              if (!active && val) {
                setActive(true);
              }
              if (active && !val) {
                setActive(false);
              }
              setSearchValue(val);

              if (val.length >= minQueryThreshold) {
                setResults(searchIndex.search(trimQuery(val)));
              } else if (results.length > 0) {
                setResults([]);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'ArrowDown') {
                setSelectedResult(Math.min(selectedResult + 1, maxSelectedResult));
                setActive(true);
              } else if (e.key === 'ArrowUp') {
                setSelectedResult(Math.max(selectedResult - 1, -1));
              } else if (e.key === 'Enter') {
                if (selectedResult < maxSelectedResult && results[selectedResult]) {
                  onSelectResult(results[selectedResult]);
                } else if (results.length > 0) {
                  onSearch(searchValue);
                  setActive(false);
                }
              }
            }}
          />

          {active && searchValue.length >= 2 && (
            <Paper classes={{ root: classes.searchResultsPaper }}>
              {results.slice(0, maxResults).map((item, i) => (
                <MenuItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  selected={selectedResult === i}
                  onMouseDown={() => onSelectResult && onSelectResult(item)}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: annotateMatches(searchValue, item.title),
                    }}
                  />
                </MenuItem>
              ))}

              {results.length > maxResults && (
                <MenuItem
                  key="show-all"
                  selected={selectedResult === maxSelectedResult}
                  onMouseDown={() => onSearch(searchValue)}
                >
                  <span className="link">
                    {t('show all results')} ({results.length})
                  </span>
                </MenuItem>
              )}

              {results.length === 0 && <MenuItem disabled>{t('No results found.')}</MenuItem>}
            </Paper>
          )}
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
}

function annotateMatches(query, title) {
  let res = title.replace(/\\/g, '');

  query
    .trim()
    .split(' ')
    .forEach((p) => {
      if (p.length < 2) {
        return;
      }

      res = res.replace(new RegExp(`(${p.replace(/vr/i, 'v-?r')})(?![^<]*>|[^<>]*</)`, 'gi'), '<strong>$1</strong>');
    });

  return res;
}

function trimQuery(query) {
  return query.trim().replace(/vr/i, 'v-?r');
}

SearchBox.defaultProps = {
  minQueryThreshold: 2,
  maxResults: 6,
};

SearchBox.propTypes = {
  minQueryThreshold: PropTypes.number.isRequired,
  maxResults: PropTypes.number.isRequired,
  onSelectResult: PropTypes.func,
  onSearch: PropTypes.func,
};

export default connect((state) => ({
  value: state.routing.query ? state.routing.query.q || '' : '',
}))(withStyles(styles)(withTranslation()(SearchBox)));
