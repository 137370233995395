import { call, put } from 'redux-saga/effects';

export default function* globalNoticeRootSaga() {
  const response = yield call(fetch, `${window.WEBSITE_API}${window.GLOBAL_NOTICE_PATH}`);

  if (response.ok) {
    const data = yield response.json();
    yield put({ type: 'SET_GLOBAL_NOTICE_DATA', data });
  }
}
