const initialState = {
  builds: [],
  loading: true,
  error: null,
};

export function buildDetails(state = initialState, payload) {
  switch (payload.type) {
    case 'BUILD_DETAILS#START':
      return { ...state, loading: true };
    case 'BUILD_DETAILS#COMPLETE':
      return { ...state, loading: false, builds: payload.data };
    case 'BUILDS_SEARCH#ERROR':
      return { ...state, loading: false, error: payload.data };
    case 'BUILDS_SEARCH#CLEAR':
      return { ...state, builds: [] };
    default:
      return state;
  }
}
