/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThermsDialog from './ThermsDialog';

export default function DownloadButton({ icon, size, children, vrscan }) {
  const [modalOpen, setModalOpen] = useState(false);
  const isTermsAccepted = window.localStorage && window.localStorage.getItem('terms_accepted') === 'accepted';
  const downloadLink = `${window.API}/vrscans/${vrscan.id}/download`;

  function handleDownload(e) {
    e.preventDefault();
    if (!isTermsAccepted) {
      setModalOpen(true);
      return;
    }

    window.location = downloadLink;
  }

  return (
    <>
      <Button variant="contained" size={size} color="primary" onClick={(e) => handleDownload(e)}>
        {icon}
        {children}
      </Button>

      {!isTermsAccepted && modalOpen && (
        <ThermsDialog
          onCancel={() => setModalOpen(false)}
          onConfirm={() => {
            setModalOpen(false);
            window.location = downloadLink;
          }}
        />
      )}
    </>
  );
}

DownloadButton.propTypes = {
  vrscan: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
};

DownloadButton.defaultProps = {
  vrscan: {},
  size: 'large',
};
