/* eslint-disable no-param-reassign */
import { put, fork, select, takeEvery, takeLatest, call } from 'redux-saga/effects';
import { equal } from 'redux-saga-first-router';
import { Api } from '../Utils/Api';
import { LICENSE_TYPES, EXCEPTION_VERSIONS, groupBuilds } from '../Utils/Builds';
import { watchRouteChange } from '../App/appSagas';
import { handleLanguage, navigateWithLanguage } from '../../sagas';
import { collections } from '../Utils/collections';
import { hideProduct } from '../Utils/products';

export function* navigateBuilds() {
  const route = yield select((state) => state.routing);

  yield fork(watchFilterChange);
  yield fork(watchRouteChange);
  yield fork(handleLanguage);

  yield call(setFilter, '', route.query);
  yield call(fetchBuilds);
  yield call(populateFilter);
  yield put({ type: 'BUILDS#SEARCH' });
}

export function* fetchBuilds() {
  const filter = yield select((state) => state.builds.filter);
  const queryString = filterToQueryString(filter);
  if (queryString === '' || hideProduct(filter.product)) {
    return;
  }

  try {
    yield put({ type: 'BUILDS#START' });
    const { Builds } = yield call(Api.fetch, `/builds${queryString}`);
    if (!Builds || Builds.length === 0) {
      yield put(navigateWithLanguage('BUILDS'));
      return;
    }
    const builds = Builds.map((b) => {
      if (b.platformVersions === undefined) {
        b.platformVersions = [];
      }

      // build platform version name
      b.platformVersionName = collections.toSentence(b.platformVersions.map((v) => v.name));
      return b;
    });
    yield put({ type: 'BUILDS#COMPLETE', data: builds });
  } catch (error) {
    yield put({ type: 'BUILDS#ERROR', error });
  }
}

export function* watchFilterChange() {
  yield takeEvery('BUILDS#FILTER#SET', onFilterChange);
  yield takeEvery('BUILDS#FILTER#ADD', onFilterChange);
  yield takeLatest('BUILDS#SEARCH', generateBuildsList);
}

const filterToQueryString = (params) => {
  if (params.product && params.platform) {
    return `?productId=${params.product}&platformId=${params.platform}`;
  }
  return '';
};

export function* setFilter(_, params) {
  if (!params) {
    yield put({ type: 'BUILDS#FILTER#CLEAR' });
  }
  if (params && params.license_type && params.license_type === 'trial') {
    params.license_type = 'adv';
  }
  yield put({ type: 'BUILDS#FILTER#SET', data: params || {} });
}

export function* onFilterChange() {
  const filter = yield select((state) => state.builds.filter);
  const route = yield select((state) => state.routing);

  if (!equal(route.query || {}, filter)) {
    yield put(navigateWithLanguage('BUILDS', {}, { query: filter }));
    yield call(fetchBuilds);
  }
  yield put({ type: 'BUILDS#GENERATE#CLEAR' });
  yield call(populateFilter);
}

// eslint-disable-next-line complexity
function* populateFilter() {
  const builds = yield select((state) => state.builds.builds);
  const filter = yield select((state) => state.builds.filter);
  yield put({ type: 'BUILDS#FILTER#PARAMS#CLEAR' });

  const specialLicenseTypes = ['license_type_beta', 'integrators'];

  // available platform versions
  const platformVersions = builds
    .filter((item) => !specialLicenseTypes.includes(item.licenseType.name))
    .map((item) => item.platformVersions || [])
    .flat()
    .filter((i) => Object.keys(i).length > 0)
    .map((i) => {
      return { key: i.id, value: platformExceptions(i.name) };
    })
    // eslint-disable-next-line no-nested-ternary
    .sort((a, b) => (a.value !== b.value ? (a.value > b.value ? -1 : 1) : 0));

  const uniquePlatformVersions = removeDuplicates(platformVersions, 'key').filter(
    (i) => i.key !== 0 && i.key !== undefined,
  );

  yield put({
    type: 'BUILDS#FILTER#OPTIONS#PLATFORM',
    data: uniquePlatformVersions,
  });

  let platformVersion = null;
  if (uniquePlatformVersions.length === 1) {
    platformVersion = uniquePlatformVersions[0].key;
  } else if (uniquePlatformVersions.length === 0) {
    platformVersion = -1;
  } else {
    platformVersion = filter.platform_version;
  }
  // show all platform versions
  if (filter.platform_version === 'all') {
    platformVersion = -1;
  }
  yield put({ type: 'BUILDS#FILTER#PARAMS#ADD', data: { platform_version: platformVersion } });

  // available license types
  const licenseTypes = builds
    .filter((i) =>
      platformVersion > 0 ? i.platformVersions.map((v) => v.id).includes(parseInt(platformVersion, 10)) : true,
    )
    .map((item) => item.licenseType)
    .filter((i) => i && Boolean(LICENSE_TYPES[i.name]))
    .map((i) => {
      return { key: i.name, value: LICENSE_TYPES[i.name].value };
    })
    .sort((a, b) => (a.value === b.value ? 0 : Number(a.value > b.value) || -1));
  const uniqueLicenseTypes = removeDuplicates(licenseTypes, 'key').filter((i) => i.key !== 'trial');
  yield put({
    type: 'BUILDS#FILTER#OPTIONS#LICENSETYPE',
    data: uniqueLicenseTypes,
  });

  let licenseType = null;
  if (uniqueLicenseTypes.length === 1 && uniquePlatformVersions.length <= 1) {
    licenseType = uniqueLicenseTypes[0].key;
  } else {
    licenseType = filter.license_type;
  }
  if (uniquePlatformVersions.length <= 1 && !specialLicenseTypes.includes(licenseType)) {
    licenseType = 'adv';
    if (uniqueLicenseTypes[0] && uniqueLicenseTypes[0].key === 'free') {
      licenseType = 'free';
    }
  }
  yield put({ type: 'BUILDS#FILTER#PARAMS#ADD', data: { license_type: licenseType } });

  // trigger search
  yield put({ type: 'BUILDS#SEARCH' });
}

function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

function* generateBuildsList() {
  const builds = yield select((state) => state.builds.builds);
  const filterParams = yield select((state) => state.builds.filterParams);

  let filteredBuilds = builds;
  const selectedPlatformVersion = parseInt(filterParams.platform_version, 10);
  if (filterParams.platform_version > 0) {
    filteredBuilds = filteredBuilds.filter((i) =>
      i.platformVersions.map((v) => v.id).includes(selectedPlatformVersion),
    );
  }
  filteredBuilds = filteredBuilds.filter((i) => i.licenseType.name === filterParams.license_type);

  let groupBy = 'default';
  if (parseInt(filterParams.platform_version, 10) === -1) {
    groupBy = 'platform';
  }

  const groupedBuilds = groupBuilds(filteredBuilds, groupBy, selectedPlatformVersion);
  yield put({ type: 'BUILDS#GENERATE', data: groupedBuilds });
}

export const platformExceptions = (platform) => {
  if (EXCEPTION_VERSIONS.includes(platform)) {
    return ` ${platform}`;
  }
  return platform;
};
