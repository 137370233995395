import React from 'react';
import IconError from '@material-ui/icons/Error';

import '../../styles/error.sass';

export default function NotFound() {
  return (
    <div className="error-page">
      <IconError fontSize="large" />
      <h3>
        <strong>Oops...</strong>
      </h3>
      <div className="error">Something went wrong</div>
      <h5 id="msg-error">
        We looked everywhere. Looks like this page is missing.
        <br />
        If you still experience problems, <a href={`${window.WEBSITE_API}/../support/faq`}>contact our Support</a>
      </h5>
    </div>
  );
}
