const initialGlobalNoticeState = {
  button: {
    id: '',
    rel: '',
    link: '',
    type: '',
    label: '',
    target: '',
  },
  text: '',
  theme: '',
  background_color: '',
  icon_url: '',
};

export function globalNotice(state = initialGlobalNoticeState, payload) {
  switch (payload.type) {
    case 'SET_GLOBAL_NOTICE_DATA':
      return payload.data;
    default:
      return state;
  }
}
