import { put, select } from 'redux-saga/effects';
import { navigate } from 'redux-saga-first-router';

// eslint-disable-next-line complexity
export function* navigateRedirect() {
  const routing = yield select((state) => state.routing);

  switch (routing.id) {
    case 'R_LS':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Standalone.id, product: window.productFamilies.LicenseServer.id } },
        ),
      );
      break;
    case 'R_COSMOS':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Standalone.id, product: window.productFamilies.Cosmos.id } },
        ),
      );
      break;
    case 'R_PDPLAYER':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Standalone.id, product: window.productFamilies.Pdplayer.id } },
        ),
      );
      break;
    case 'R_PHOENIX':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms['3dsMax'].id, product: window.productFamilies.Phoenix.id } },
        ),
      );
      break;
    case 'R_PHOENIX_MAX':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms['3dsMax'].id, product: window.productFamilies.Phoenix.id } },
        ),
      );
      break;
    case 'R_PHOENIX_MAYA':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Maya.id, product: window.productFamilies.Phoenix.id } },
        ),
      );
      break;
    case 'R_APP_SDK':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Standalone.id, product: window.productFamilies.VRayAppSdk.id } },
        ),
      );
      break;
    case 'R_VRAY_MAX':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms['3dsMax'].id, product: window.productFamilies.VRay.id } },
        ),
      );
      break;
    case 'R_VRAY_MAYA':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Maya.id, product: window.productFamilies.VRay.id } },
        ),
      );
      break;
    case 'R_VRAY_NUKE':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Nuke.id, product: window.productFamilies.VRay.id } },
        ),
      );
      break;
    case 'R_VRAY_REVIT':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Revit.id, product: window.productFamilies.VRay.id } },
        ),
      );
      break;
    case 'R_VRAY_RHINO':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Rhino.id, product: window.productFamilies.VRay.id } },
        ),
      );
      break;
    case 'R_VRAY_SKETCHUP':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Sketchup.id, product: window.productFamilies.VRay.id } },
        ),
      );
      break;
    case 'R_VRAY_STANDALONE':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Standalone.id, product: window.productFamilies.VRay.id } },
        ),
      );
      break;
    case 'R_VRAY_SOFTIMAGE':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Softimage.id, product: window.productFamilies.VRay.id } },
        ),
      );
      break;
    case 'R_VRAY_SWARM':
      yield put(
        navigate(
          'BUILDS',
          {},
          { query: { platform: window.platforms.Standalone.id, product: window.productFamilies.VRaySwarm.id } },
        ),
      );
      break;
    default:
  }
}
