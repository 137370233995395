export const pt = {
  translation: {
    'Select your product.': 'Selecione seu produto.', // New
    'Select the version.': 'Select the version.',
    'Select the version of your host platform.': 'Select the version of your host platform.',
    Download: 'Download',
    Downloads: 'Downloads',
    'Back to all products': 'Voltar para todos os produtos', // New
    'Use the filter to find your product': 'Use o filtro para encontrar seu produto', // New
    'See all products': 'Ver todos os produtos', // New
    'Download for another OS': 'Download para outro OS',
    'Download for': 'Download para',
    'Show all versions': 'Mostrar todas as versões', // New
    'All fields are required': 'Todos os campos são necessários', // New
    'Platform version': 'Versão da plataforma',
    'License type': 'Tipo de licença',
    'Please select': 'Por favor selecione', // New
    Search: 'Buscar', // New
    '1 item found': '1 ítem encontrado',
    '{n} items found': '{{n}} ítens encontrados',
    'Release date': 'Data de Lançamento',
    'File Size': 'Tamanho do arquivo',
    'File size': 'Tamanho do arquivo',
    "What's new": 'Novidades',
    'Requirements & changelog': 'Requisitos & changelog',
    items: 'Ítems',
    new: 'new', // New
    'Uploaded before': 'Carregado antes de', // New
    'Material type': 'Tipo de material',
    'Material color': 'Cor do Material',
    'Suitable for': 'Adequado para',
    Manufacturer: 'Fabricante',
    Manufacturers: 'Fabricantes', // New
    Tags: 'Tags',
    'Clear all': 'Limpar tudo',
    'File name': 'Nome do arquivo',
    'Note: Due to manufacturing process and use of natural materials, final color may vary. Please reach out to the manufacturer for more details.':
      'Por causa das especificidades do processo de fabricação e o uso de materiais naturais, é possível para a cor real e padrão dos materiais físicos para variar.',
    'Search Chaos Scans': 'Buscar Chaos Scans',
    'show all results': 'Ver todos os resultados',
    'No results found.': 'Nenhum resultado encontrado',

    // Filters
    '1 Week': '1 Semana', // New
    '1 Month': '1 Mês', // New
    '6 Months': '6 Meses', // New

    'Car Paint': 'Pintura Automotiva',
    Carbon: 'Carbono',
    Ceramic: 'Cerâmica',
    Fabric: 'Tecido',
    Foam: 'Espuma',
    Foil: 'Folha Metálica',
    Hologram: 'Holograma',
    Leather: 'Couro',
    Metal: 'Metal',
    Paint: 'Tinta',
    Paper: 'Papel',
    Plastic: 'Plástico',
    Rubber: 'Borracha',
    Wood: 'Madeira',

    Automotive: 'Automotivo',
    'Interior Design': 'Design de Interiores',
    'Product Design': 'Design de Produto',
    Aerospace: 'Aerospacial', // New

    Generic: 'Genérico',
    'Townsend Leather Company, Inc.': 'Townsend Leather Company, Inc.',
    'Handy Living': 'Handy Living',
    'Kawashima Selkon Textiles Co., Ltd.': 'Kawashima Selkon Textiles Co., Ltd.',
    'Nihon Etching Co., Ltd.': 'Nihon Etching Co., Ltd.',
    'Tanazawa Hakkosha Co., Ltd': 'Tanazawa Hakkosha Co., Ltd',

    Alcantara: 'Alcântara',
    Aluminum: 'Alumínio',
    Brass: 'Latão',
    Brushed: 'Escovado',
    Copper: 'Cobre',
    Crocodile: 'Crocodilo',
    Galvanized: 'Galvanizado',
    Glossy: 'Brilhante',
    Gold: 'Ouro',
    Matte: 'Fosco',
    Mirror: 'Espelho',
    Pattern: 'Padrão',
    Perforated: 'Perfurado',
    Rust: 'Ferrugem',
    Scratched: 'Riscado',
    Tin: 'Lata',
    Volumetric: 'Volumétrico',
    'V-Ray Next Recommended': 'Recomendado para V-Ray Next', // New
    Wool: 'Lã', // New
    Retroreflective: 'Retro-reflexivo', // New
    Chameleon: 'Camaleão', // New
    Grain: 'Grão', // New
    Flat: 'Liso', // New
    Smooth: 'Suave', // New
    Translucent: 'Translucente', // New

    // Colors
    White: 'Branco',
    Yellow: 'Amarelo',
    Beige: 'Bege',
    Orange: 'Laranja',
    Fuchsia: 'Magenta',
    Red: 'Vermelho',
    Pink: 'Rosa',
    Silver: 'Prata',
    Gray: 'Cinza',
    Olive: 'Oliva',
    Purple: 'Roxo',
    Maroon: 'Castanho',
    Aqua: 'Aqua',
    Lime: 'Lima',
    Teal: 'Verde-água',
    Green: 'Verde',
    Blue: 'Azul',
    Navy: 'Naval',
    Brown: 'Marrom',
    Black: 'Preto',
  },
};
