import React from 'react';

export function EnscapeModal({ setEnscapeModalProps, downloadButtonId, t, id }) {
  return (
    <div
      className="EnscapeModal"
      onClick={(event) => {
        event.stopPropagation();
        setEnscapeModalProps(null);
      }}
    >
      <div className="content" onClick={(event) => event.stopPropagation()}>
        <h4>Important information regarding Enscape licensing changes in version 4.1</h4>
        <p>
          To avoid potential challenges, please read&nbsp;
          <a target="_blank" rel="noreferrer" href="https://learn.enscape3d.com/blog/knowledgebase/chaos-licensing">
            this article
          </a>
          &nbsp;before you install.
        </p>
        <a id={downloadButtonId} href={`${window.API}/builds/${id}/download`} className="dl">
          {t('Proceed')}
        </a>
      </div>
    </div>
  );
}
