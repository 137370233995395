/* eslint-disable no-restricted-syntax */
import { put, call, select, fork, cancelled, takeLatest } from 'redux-saga/effects';
import Fuse from 'fuse.js';
import { halt, handleLanguage, navigateWithLanguage } from '../../sagas';

import { Api } from '../Utils/Api';
import { strings } from '../Utils/strings';
import { watchRouteChange } from '../App/appSagas';

export const PER_PAGE = 12;

export function* navigateVrscans() {
  try {
    yield fork(watchNavigateRoute);
    yield fork(watchNavigatePage);
    yield fork(watchFiltersChange);
    yield fork(watchQueryChange);
    yield fork(watchFiltersClear);
    yield fork(watchRouteChange);
    yield fork(handleLanguage);
    yield call(getVrscans);
    yield call(halt);
  } finally {
    if (yield cancelled()) {
      yield put({ type: 'VRSCANS#RESET' });
    }
  }
}

export function* navigateVrscan(payload) {
  yield fork(handleLanguage);
  try {
    yield call(getVrscan, payload.id);
    yield call(halt);
  } finally {
    if (yield cancelled()) {
      yield put({ type: 'VRSCAN#RESET' });
    }
  }
}

export function* navigateVrscansQuery() {
  const vrscansState = yield select((state) => state.vrscans);
  const filters = yield call(extractFilters, vrscansState.filteredByQuery);
  const activeFilters = yield call(getRouteFilters, filters);
  const filtered = yield call(getFilteredVrscans, vrscansState.filteredByQuery, activeFilters);
  const offset = yield call(currentOffset);

  yield put({ type: 'VRSCANS#SET_FILTERED', data: { filtered, activeFilters, offset } });
}

function* getVrscans() {
  try {
    yield put({ type: 'GET_VRSCANS#START' });

    const query = yield select((state) => state.routing.query || {});
    const vrscans = yield call(Api.fetch, '/vrscans');
    vrscans.forEach((vr) => {
      Object.assign(vr, { uploadedScope: daysToNamedScope(vr.createdAt) });
    });
    const searchIndex = yield call(buildSearchIndex, vrscans);
    const filteredByQuery = yield call(getQueryFilteredVrscans, { vrscans, searchIndex, query: query.q || '' });
    const filters = yield call(extractFilters, filteredByQuery);
    const activeFilters = yield call(getRouteFilters, filters);
    const filtered = yield call(getFilteredVrscans, filteredByQuery, activeFilters);
    const offset = yield call(currentOffset);

    yield put({
      type: 'GET_VRSCANS#COMPLETE',
      data: {
        items: vrscans,
        searchIndex,
        filteredByQuery,
        filters,
        filtered,
        activeFilters,
        offset,
      },
    });
  } catch (error) {
    yield put({ type: 'GET_VRSCANS#ERROR', data: { error } });
  }
}

function* getVrscan(id) {
  try {
    yield put({ type: 'GET_VRSCAN#START' });
    const data = yield call(Api.fetch, `/vrscans/${id}`);
    yield put({ type: 'GET_VRSCAN#COMPLETE', data: data.VRScan });
  } catch (error) {
    yield put({ type: 'GET_VRSCAN#ERROR', data: { error } });
  }
}

function extractFilters(vrscans) {
  const filters = {
    materialType: new Map(),
    colors: new Map(),
    industries: new Map(),
    manufacturer: new Map(),
    tags: new Map(),
    uploadedScope: new Map(),
  };

  for (let i = 0; i < vrscans.length; i += 1) {
    filters.materialType.set(vrscans[i].materialType.id, { name: vrscans[i].materialType.name });
    vrscans[i].colors.map((c) => filters.colors.set(c.id, { name: c.name, key: c.key, hex: c.hex }));
    vrscans[i].industries.map((ind) => filters.industries.set(ind.id, { name: ind.name }));
    filters.manufacturer.set(vrscans[i].manufacturer.id, {
      name: vrscans[i].manufacturer.name,
      logoFileName: vrscans[i].manufacturer.logoFileName,
      website: vrscans[i].manufacturer.website,
    });
    if (vrscans[i].tags) {
      vrscans[i].tags.forEach((t) => t && filters.tags.set(t.id, { name: t.name }));
    }
  }

  Object.values(dateScopes).map((scope) => filters.uploadedScope.set(scope.id, { name: scope.name }));

  return filters;
}

const dateScopes = {
  weekly: { id: 1, name: '1 Week' },
  monthly: { id: 2, name: '1 Month' },
  half_year: { id: 3, name: '6 Months' },
};

function daysToNamedScope(createdAt) {
  const timeDiff = Date.now() - new Date(createdAt).getTime();
  const daysDiff = Math.floor(timeDiff / (24 * 60 * 60 * 1000));

  switch (true) {
    case daysDiff > 0 && daysDiff <= 7:
      return dateScopes.weekly;
    case daysDiff > 7 && daysDiff <= 31:
      return dateScopes.monthly;
    case daysDiff > 31 && daysDiff <= 182:
      return dateScopes.half_year;
    default:
      return {};
  }
}

function* filterChangeHandler({ filterType, id, value }) {
  const vrscansState = yield select((state) => state.vrscans);

  const activeFilters = { ...vrscansState.activeFilters };
  if (!value && filterType in activeFilters && id in activeFilters[filterType]) {
    delete activeFilters[filterType][id];
    if (Object.values(activeFilters[filterType]).length === 0) {
      delete activeFilters[filterType];
    }
  } else {
    if (!(filterType in activeFilters)) {
      activeFilters[filterType] = {};
    }
    activeFilters[filterType][id] = value;
  }
  const filtered = yield call(getFilteredVrscans, vrscansState.filteredByQuery, activeFilters);
  yield put({ type: 'VRSCANS#SET_FILTERED', data: { filtered, activeFilters } });
  yield call(setRouteFilters, activeFilters);
}

function* clearFiltersHandler() {
  const vrscansState = yield select((state) => state.vrscans);

  const filtered = yield call(getFilteredVrscans, vrscansState.filteredByQuery, {});
  yield put({ type: 'VRSCANS#SET_FILTERED', data: { filtered, activeFilters: {} } });
  yield call(setRouteFilters, {});
}

function* queryChangeHandler(payload) {
  const { items, searchIndex } = yield select((state) => state.vrscans);

  const filteredByQuery = yield call(getQueryFilteredVrscans, { vrscans: items, searchIndex, query: payload.query });
  const filters = yield call(extractFilters, filteredByQuery);
  const activeFilters = yield call(getRouteFilters, filters);
  const filtered = yield call(getFilteredVrscans, filteredByQuery, activeFilters);

  yield call(setRouteFilters, activeFilters, payload.query);

  yield put({ type: 'VRSCANS#SET_FILTERED', data: { filtered, filteredByQuery, filters, activeFilters } });
}

function getQueryFilteredVrscans({ vrscans, searchIndex, query }) {
  return query ? searchIndex.search(query).map((i) => vrscans.find((s) => s.id === i.id)) : vrscans;
}

function getFilteredVrscans(vrscans, filters) {
  const filterKeys = Object.keys(filters);

  if (filterKeys.length === 0) {
    return vrscans;
  }

  const filtered = vrscans.filter((vrscan) => {
    for (const [filterKey, filterValues] of Object.entries(filters)) {
      const vrscanValues = (Array.isArray(vrscan[filterKey]) ? vrscan[filterKey] : [vrscan[filterKey]]).map(
        (vrscanValue) => (vrscanValue ? vrscanValue.id : null),
      );
      const filterMatch = Object.keys(filterValues).some((filterValue) =>
        vrscanValues.includes(parseInt(filterValue, 10)),
      );
      if (!filterMatch) {
        return false;
      }
    }
    return true;
  });

  return filtered;
}

function buildSearchIndex(vrscans) {
  const items = vrscans.map((vrscan) => ({
    id: vrscan.id,
    title: [
      vrscan.name,
      vrscan.materialType.name,
      vrscan.colors ? vrscan.colors.map((c) => c.name).join(', ') : null,
      vrscan.manufacturer.name,
      vrscan.licenses ? vrscan.licenses.map((l) => l.name).join(', ') : null,
      vrscan.industries ? vrscan.industries.map((l) => l.name).join(', ') : null,
      vrscan.tags ? vrscan.tags.map((t) => (t ? t.name : null)).join(', ') : null,
    ]
      .filter((v) => v)
      .join(' '),
    industries: vrscan.industries,
    manufacturer: vrscan.manufacturer.name,
    tags: vrscan.tags && vrscan.tags[0] ? vrscan.tags : [],
    colors: vrscan.colors,
    slug: vrscan.fileName.replace('.vrscan', ''),
  }));

  return new Fuse(items, {
    threshold: 0.4,
    tokenize: true,
    matchAllTokens: true,
    keys: [
      {
        name: 'title',
        weight: 0.2,
      },
      {
        name: 'industries.name',
        weight: 0.1,
      },
      {
        name: 'manufacturer',
        weight: 0.3,
      },
      {
        name: 'tags.name',
        weight: 0.1,
      },
      {
        name: 'colors.name',
        weight: 0.3,
      },
    ],
  });
}

function* navigatePage(payload) {
  const routing = yield select((state) => state.routing);
  yield put({ type: 'VRSCANS#SET_OFFSET', data: { offset: payload.data.offset } });
  const page = payload.data.offset / PER_PAGE + 1;
  const query = { ...routing.query };
  if (page > 1) {
    query.page = page;
  } else if ('page' in query) {
    delete query.page;
  }

  yield put(navigateWithLanguage('VRSCANS', {}, { query }));
}

function* getRouteFilters(filtersAvailable) {
  const route = yield select((state) => state.routing);

  const routeFilters = {};
  if (!route.query) {
    return routeFilters;
  }

  Object.entries(route.query).forEach((queryParam) => {
    const filterType = strings.toCamelCase(queryParam[0]);
    if (!filtersAvailable[filterType]) {
      return;
    }

    const values = queryParam[1] instanceof Array ? queryParam[1] : [queryParam[1]];
    values.forEach((v) => {
      const filterValue = parseInt(v, 10);
      if (!filtersAvailable[filterType].has(filterValue)) {
        return;
      }
      if (!(filterType in routeFilters)) {
        routeFilters[filterType] = {};
      }
      routeFilters[filterType][filterValue] = true;
    });
  });

  return routeFilters;
}

function* setRouteFilters(filters, searchQuery) {
  const queryParams = {};
  for (const [filterType, values] of Object.entries(filters)) {
    const ftype = strings.toSnakeCase(filterType);
    if (!(ftype in queryParams)) {
      queryParams[ftype] = [];
    }
    queryParams[ftype] = Object.keys(values);
  }

  const routing = yield select((state) => state.routing);
  if (searchQuery) {
    queryParams.q = searchQuery;
  } else if (routing.query && routing.query.q && searchQuery !== '') {
    queryParams.q = routing.query.q;
  }

  yield put(navigateWithLanguage('VRSCANS', {}, { query: queryParams }));
}

function* currentOffset() {
  const routing = yield select((state) => state.routing);
  return routing.query && routing.query.page ? (routing.query.page - 1) * PER_PAGE : 0;
}

function* watchNavigatePage() {
  yield takeLatest('VRSCANS#NAVIGATE_PAGE', navigatePage);
}

function* watchFiltersChange() {
  yield takeLatest('VRSCANS#FILTER_CHANGE', filterChangeHandler);
}

function* watchQueryChange() {
  yield takeLatest('VRSCANS#HANDLE_QUERY_CHANGE', queryChangeHandler);
}

function* watchFiltersClear() {
  yield takeLatest('VRSCANS#FILTERS_CLEAR_SELECTED', clearFiltersHandler);
}

function* watchNavigateRoute() {
  yield takeLatest('router/NAVIGATE', getVrscansOnQueryChange);
}

function* getVrscansOnQueryChange(payload) {
  if (payload.id !== 'VRSCANS') {
    return;
  }
  const routing = yield select((state) => state.routing);
  if (!routing.query && routing.prev.query) {
    yield call(getVrscans);
  }
}
