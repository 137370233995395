export const es = {
  translation: {
    'Select your product.': 'Seleccione su producto.', // New
    'Select the version.': 'Select the version.',
    'Select the version of your host platform.': 'Select the version of your host platform.',
    Download: 'Descargar',
    Downloads: 'Descargas',
    'Back to all products': 'Regresar a todos los productos', // New
    'Use the filter to find your product': 'Utilice el filtro para encontrar su producto', // New
    'See all products': 'Ver todos los productos', // New
    'Download for another OS': 'Descargar para otro SO',
    'Download for': 'Descargar para',
    'Show all versions': 'Mostrar todas las versiones', // New
    'All fields are required': 'Todos los campos son obligatorios', // New
    'Platform version': 'Versión de la plataforma',
    'License type': 'Tipo de licencia',
    'Please select': 'Por favor seleccione', // New
    Search: 'Buscar', // New
    '1 item found': '1 Artículo encontrado',
    '{n} items found': '{{n}} Artículos encontrados',
    'Release date': 'Fecha de lanzamiento',
    'File Size': 'Tamaño de archivo',
    'File size': 'Tamaño de archivo',
    "What's new": '¿Qué es lo nuevo?',
    'Requirements & changelog': 'Requerimientos y changelog',
    items: 'Artículos',
    new: 'new', // New
    'Uploaded before': 'Subido antes', // New
    'Material type': 'Tipo de material',
    'Material color': 'Color de material',
    'Suitable for': 'Adecuado para',
    Manufacturer: 'Manufactura',
    Manufacturers: 'Fabricantes', // New
    Tags: 'Etiquetas',
    'Clear all': 'Limpiar todo',
    'File name': 'Nombre de archivo',
    'Note: Due to manufacturing process and use of natural materials, final color may vary. Please reach out to the manufacturer for more details.':
      'Ebido a los detalles de la proceso de fabricación y el uso de materiales naturales, es posible para el color real y Patrón de los materiales físicos para variar.',
    'Search Chaos Scans': 'Buscar Chaos Scans',
    'show all results': 'Ver todos los resultados',
    'No results found.': 'Ningún resultado encontrado',

    // Filters
    '1 Week': '1 Semana', // New
    '1 Month': '1 Mes', // New
    '6 Months': '6 Meses ', // New

    'Car Paint': 'Pintura de auto',
    Carbon: 'Carbón',
    Ceramic: 'Cerámica',
    Fabric: 'Tela',
    Foam: 'Espuma',
    Foil: 'Foil',
    Hologram: 'Holograma',
    Leather: 'Piel',
    Metal: 'Metal',
    Paint: 'Pintura',
    Paper: 'Papel',
    Plastic: 'Plástico',
    Rubber: 'Goma',
    Wood: 'Madera',

    Automotive: 'Automotriz',
    'Interior Design': 'Diseño de Interiores',
    'Product Design': 'Diseño de Producto',
    Aerospace: 'Aeroespacial', // New

    Generic: 'Genérico',
    'Townsend Leather Company, Inc.': 'Townsend Leather Company, Inc.',
    'Handy Living': 'Handy Living',
    'Kawashima Selkon Textiles Co., Ltd.': 'Kawashima Selkon Textiles Co., Ltd.',
    'Nihon Etching Co., Ltd.': 'Nihon Etching Co., Ltd.',
    'Tanazawa Hakkosha Co., Ltd': 'Tanazawa Hakkosha Co., Ltd',

    Alcantara: 'Alcantara',
    Aluminum: 'Aluminio',
    Brass: 'Latón',
    Brushed: 'Cepillado',
    Copper: 'Cobre',
    Crocodile: 'Cocodrilo',
    Galvanized: 'Galvanizado',
    Glossy: 'Glossy',
    Gold: 'Oro',
    Matte: 'Matte',
    Mirror: 'Espejo',
    Pattern: 'Patrón',
    Perforated: 'Perforado',
    Rust: 'Óxido',
    Scratched: 'Rayado',
    Tin: 'Estaño',
    Volumetric: 'Volumétrico',
    'V-Ray Next Recommended': 'Recomendado V-Ray Next', // New
    Wool: 'Lana', // New
    Retroreflective: 'Retroreflexivo', // New
    Chameleon: 'Camaleón ', // New
    Grain: 'Grano', // New
    Flat: 'Plano', // New
    Smooth: 'Suave', // New
    Translucent: 'Translúcido', // New

    // Colors
    White: 'Blanco',
    Yellow: 'Amarillo',
    Beige: 'Beige',
    Orange: 'Naranja',
    Fuchsia: 'Fucsia',
    Red: 'Rojo',
    Pink: 'Rosa',
    Silver: 'Plata',
    Gray: 'Gris',
    Olive: 'Oliva',
    Purple: 'Purpura',
    Maroon: 'Marrón',
    Aqua: 'Agua',
    Lime: 'Lima',
    Teal: 'Teal',
    Green: 'Verde',
    Blue: 'Azul',
    Navy: 'Navy',
    Brown: 'Café',
    Black: 'Negro',
  },
};
