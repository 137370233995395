import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Webshop, WebshopButton } from 'webshop';
import { Footer, Header } from '@chaos/layout';

import Menu from './Header/Menu';
import GlobalNotice from './GlobalNotice';

import '../../styles/page.sass';
import '@chaos/layout/dist/index.css';

function Layout({ children, auth, onLangChange, i18n, t }) {
  const { language } = i18n;

  if (auth.UserID === 0) {
    return null;
  }
  const user = { fullName: `${auth.FirstName} ${auth.LastName}`, email: auth.Email };
  const langSegment = language === 'en' ? '' : `/${language}`;

  const shoppingCartButton = {
    component: () => <WebshopButton language={language} theme="chaos" env={window.ENV} />,
  };

  return (
    <div id="page">
      <GlobalNotice />
      <Header
        url={`${window.WEBSITE_API}${langSegment}/api/header`}
        returnUrl={encodeURIComponent(window.location.href)}
        user={user}
        cart={shoppingCartButton}
        country={auth.Location}
        currentLanguage={language}
        languages={[
          { code: 'EN', label: 'English', link: '#' },
          { code: 'ES', label: 'Español', link: '#' },
          { code: 'PT', label: 'Português (Brasil)', link: '#' },
          { code: 'CN', label: '简体中文', link: '#' },
          { code: 'KR', label: '한국어', link: '#' },
        ]}
        onChangeLanguage={(languageCode) => onLangChange(languageCode)}
      />
      <h3 className="section-title">{t('Downloads')}</h3>
      <Menu />
      <div className="page-wrapper">{children}</div>
      <Footer url={`${window.WEBSITE_API}${langSegment}/api/footer`} />
      <Webshop language={language} theme="chaos" env={window.ENV} />
    </div>
  );
}

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  (dispatch) => ({
    onLangChange: (params) => dispatch({ type: 'LANG#CHANGE', data: params }),
  }),
)(withTranslation()(Layout));
