import { reducer as routing } from 'redux-saga-first-router';
import { combineReducers } from 'redux';
import { auth } from './app/App/appReducer';
import { builds } from './app/Builds/buildsReducer';
import { buildDetails } from './app/BuildDetails/buildDetailsReducer';
import { vrscans, vrscan } from './app/Vrscans/vrscansReducer';
import { globalNotice } from './app/Layout/GlobalNotice/globalNoticeReducer';

export default combineReducers({
  auth,
  routing,
  builds,
  vrscans,
  vrscan,
  buildDetails,
  globalNotice,
});
