import React from 'react';
import { connect } from 'react-redux';
import NotFound from '../Layout/NotFound';
import Page from '../Layout/Page';
import '../../styles/app.sass';
import '../../styles/globals.css';
import Builds from '../Builds/Builds';
import BuildDetails from '../BuildDetails/BuildDetails';
import BuildChangelog from '../BuildChangelog/BuildChangelog';
import Vrscans from '../Vrscans/Vrscans';
import Vrscan from '../Vrscans/Vrscan';

const routesComponentsMap = {
  BUILDS: () => <Builds />,
  BUILD_DETAILS: () => <BuildDetails />,
  BUILD_CHANGELOG: () => <BuildChangelog />,
  VRSCANS: () => <Vrscans />,
  VRSCAN: () => <Vrscan />,
  404: () => <NotFound />,
  500: () => <NotFound />,
};

function App({ routing: { id }, auth: { loading } }) {
  if (!routesComponentsMap[id]) {
    return (
      <Page>
        <NotFound />
      </Page>
    );
  }

  if (loading) {
    return null;
  }

  const Component = routesComponentsMap[id] || React.Fragment;

  return (
    <Page>
      <Component />
    </Page>
  );
}

export default connect((state) => ({ routing: state.routing, auth: state.auth }))(App);
