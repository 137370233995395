/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/CloseOutlined';

export default function ThermsDialog({ isOpen, onConfirm, onCancel }) {
  return (
    <Dialog open={isOpen} scroll="body" maxWidth="md" aria-labelledby="dialog-title">
      <div className="license-agreement">
        <DialogTitle id="dialog-title">
          <div className="title">LICENSE AGREEMENT</div>
          <CloseIcon className="close-icon" onClick={onCancel} />
        </DialogTitle>

        <DialogContent className="content">
          <p className="disclaimer">
            These are Chaos Software’s Terms and Conditions for use of Digital Representation Library. Please read them
            carefully before acceptance. If you do not agree to the Terms and Conditions for use of Digital
            Representation Library, or you are not allowed to enter into legally binding agreements, please click on the
            Decline button or leave this page.
          </p>

          <h3>TERMS AND CONDITIONS FOR USE OF DIGITAL REPRESENTATION LIBRARY</h3>
          <p>
            These Terms and Conditions (“the Agreement”) constitute the legal agreement between you, as an individual or
            entity (“Client”), and Chaos Software Ltd., Mladost-1A, block 548 entrance B, 2nd floor, Sofia 1729,
            Bulgaria (“Chaos”), each a “Party” and collectively “Parties”, and is entered into as of the day the
            Agreement is accepted by Client by pressing the “ACCEPT” button, clicking on an “I AGREE” check-box, or by
            using other equivalent ways of acceptance technically available as of that moment (the “Effective Date”).
          </p>

          <h3>DEFINITIONS</h3>
          <p>For the terms of this Agreement, the following definitions shall apply:</p>
          <p>
            “Digital Representations” means one or more computer files containing information about the visual
            appearance of physical samples of various real and tangible materials, grouped and categorized in libraries
            and catalogues by Chaos, subject to further improvement, deletion or change upon Chaos’ discretion.
          </p>
          <p>
            “IP Rights” means all patents, copyrights, trademarks, trade secrets and design rights and all rights or
            forms of protection of a similar nature having equivalent or similar effect to any of these, which may
            subsist anywhere in the world.
          </p>

          <h3>WHEREAS:</h3>
          <p>
            Chaos has the technology and know-how to create Digital Representations and organize them in libraries and
            catalogues;
          </p>
          <p>
            Client wishes to be granted access to such libraries and catalogues, and be entitled to use, from time to
            time, certain Digital Representations contained therein;
          </p>
          <p>
            Chaos is willing to grant Client a license to use the Digital Representations upon the terms and conditions
            contained herein, and Client is willing to accept such license.
          </p>
          <p>
            NOW, THEREFORE, in consideration of the mutual covenants contained herein, the Parties agree as follows:
          </p>

          <ul>
            <h3>1. LICENSE GRANT</h3>
            <p>
              Subject to Client’s continuous compliance with this Agreement, Chaos grants and Client accepts a right and
              license:
            </p>
            <ol>
              <li>to use the Digital Representations for Client’s 3D projects only;</li>
              <li>
                to use the proper software to adjust, if such adjustments are supported by the software, the settings of
                the Digital Representations.
              </li>
              <li>to store the Digital Representations;</li>
              <li>
                to create back-up copies of the Digital Representations, if that is needed for the specific use that
                they have been obtained for, always keeping all copyright notices and other marks of ownership on each
                copy, or partial copy, of the Digital Representations.{' '}
              </li>
            </ol>

            <li>
              <h3>2. LICENSE TERM</h3>
              <p>
                The use of the respective Digital Representation is limited with the term of this Agreement. Upon
                expiration of the Agreement or its earlier termination, Client shall cease all use of the Digital
                Representations and destroy all copies, full or partial, of the Digital Representations, unless
                otherwise agreed expressly in writing by Parties.
              </p>
            </li>

            <li>
              <h3>3. LIMITATIONS/PROHIBITED USE</h3>
              <p>Except as expressly provided for in this Agreement, Client will not:</p>
              <ul>
                <li>
                  {' '}
                  3.1. make any adaptations, arrangements, enhancements, or alterations of the Digital Representations,
                  except as permitted herein;
                </li>
                <li>
                  {' '}
                  3.2. redistribute or give the Digital Representations away in any way (sell, transfer, assign or give
                  access to, lease, rent, loan, charge, donate, exchange, share or other) to any third parties;
                </li>
                <li>
                  {' '}
                  3.3. use the Digital Representations for services that provide third parties with contracted/on demand
                  access;
                </li>
                <li>
                  {' '}
                  3.4. adapt, reverse engineer, decompile, disassemble or otherwise tamper the whole or any part of the
                  Digital Representations;
                </li>
                <li>
                  {' '}
                  3.5. remove or alter any copyright or other proprietary notice from the Digital Representations. Any
                  attempt to delete, blur, obscure, or otherwise achieve such notices and marks not to be perceived or
                  retrieved when the Digital Representations are displayed, is absolutely forbidden, and shall be
                  considered a material breach hereunder;
                </li>
                <li>
                  {' '}
                  3.6. use the Digital Representations in a manner that infringes any third party’s copyrights or any
                  other rights;
                </li>
                <li> 3.7. participate in any illegal, deceptive, misleading or unethical practices and activities.</li>
              </ul>
            </li>

            <li>
              <h3>4. COMPLIANCE</h3>
              <p>
                Client ensures that the rights and obligations under this Agreement are exercised by authorized Client’s
                employees and/or subcontractors only, on Client’s behalf, and Client is responsible at all times for
                full compliance with the terms and conditions of this Agreement by such employees and/or subcontractors.
                Client shall be liable for any unauthorized use of the Digital Representations or any other breach of
                this Agreement by its employees and/or subcontractors. Client shall notify Chaos in a timely manner, if
                Client becomes aware of any unauthorized use of the whole or any part of the Digital Representations.
              </p>
            </li>

            <li>
              <h3>5. COLLATERAL PRODUCTS</h3>
              <p>
                The Digital Representations may be accompanied, may contain or Chaos may provide from time to time other
                own and/or third party’s software, drivers, data, documents, materials, etc. (Collateral Products).
                Collateral Products may include, be subject to, or provided in accordance with other terms in addition
                to or different from the terms set forth in this Agreement. Unless such terms are included or
                referenced, Collateral Products are subject to this Agreement. In case such terms apply Client agrees to
                comply with them. Further, Client will take sole responsibility for acquiring and complying with any
                licenses that may be necessary to use or to use in a different way any Collateral Product, third party’s
                software, data, documents or other materials. Client acknowledges and agrees that, except if otherwise
                agreed in a separate agreement, Chaos has no responsibility for, and makes no representations or
                warranties regarding, such Collateral products, third party’s software, data, documents or other
                materials or Client’s use of such third party’s software, data, documents or other materials.
              </p>
            </li>

            <li>
              <h3>6. DELIVERY</h3>
              <p>
                Client shall be granted access to the Digital Representations under the terms and conditions of this
                Agreement.
              </p>
            </li>

            <li>
              <h3>7. LICENSE FEES</h3>
              <p>
                The access to the Digital Representations is complimentary to the terms and conditions of the Chaos’ End
                User License Agreement (“EULA”) and the license for VRscans plug-in, as defined in the EULA. Unless
                otherwise specified and stipulated by Chaos, it will be considered that the license for Digital
                Representations hereunder shall be used without additional payment.
              </p>
              <p>
                In case Chaos specifies and stipulates license fees to be paid in consideration of the grant of the
                license, Client shall pay to Chaos or its authorized reseller a fee, subject to change from time to time
                upon Chaos discretion, as may specified in the relevant section/s of the Chaos’ libraries and catalogues
                where the Digital Representations are stored. In case of Client’s delay to fulfill the payment within
                two months period as of the due term, Client shall immediately cease all use of the Digital
                Representations and destroy all copies, full or partial, of the Digital Representations, unless
                otherwise agreed expressly in writing by Parties.
              </p>
            </li>

            <li>
              <h3>8. CONFIDENTIALITY AND NON- DISCLOSURE</h3>
              <p>
                8.1. Chaos and Client acknowledge and agree that during the term of or in relation with this Agreement
                either Party (the “Disclosing Party”) may disclose to the other Party (the “Recipient”) certain business
                and/or technical information of a confidential and proprietary nature, including but not limited to
                trade secret, products, services, finance, prices, customers, documentation or other oral or written
                non-public information (“Confidential Information”) that Disclosing Party delivers or communicates to
                the Recipient or to which the Recipient otherwise gains access to under this Agreement. It shall be not
                considered as Confidential Information the information which: (i) is already known to, or in the
                possession of the Recipient prior to receipt of such information; (ii) is legally received by a Party
                from a third party without any confidentiality obligation; (iii) is in the public domain or enter the
                public domain through no wrongful act of the Recipient; (iv) can be proven by the Recipient to have been
                developed independently of confidential information received from the other Party.
              </p>
              <p>
                8.2. Confidential information may be used only internally and only in conjunction with and for
                Recipient’s own authorized internal use.
              </p>
              <p>
                8.3. Recipient shall maintain the confidentiality of the Disclosing Party’s Confidential Information and
                may not disclose, distribute or otherwise provide it to third parties, except in cases where such
                information is required by applicable law or court order and a prompt advance notice to Disclosing Party
                has been sent to enable the latter to seek a protective order or otherwise prevent such disclosure.
              </p>
              <p>
                8.4. At any time immediately upon request by a Disclosing Party the Recipient shall return to Disclosing
                Party all Confidential Information requested.
              </p>
            </li>

            <li>
              <h3>9. PRIVACY</h3>
              <p>
                9.1. Client acknowledges and agrees that in order to obtain and use Digital Representations Client
                (and/or third parties acting on Client's behalf) may have to provide, and Chaos and its resellers (and
                third parties acting on behalf of Chaos and its resellers) may obtain, certain personal information and
                data with respect to Client and or Client employees. By accepting the Agreement Client hereby consents
                to Chaos and its resellers processing such information and data.
              </p>
              <p>
                9.2. Chaos is a registered data administrator (Bulgarian Personal Data Protection Act (01 Jan. 2002 and
                as amended from time to time)) and as such Chaos takes appropriate technical measures to protect
                Client's personal information and data. Personal information and data provided to Chaos in connection
                with this Agreement may be processed in Bulgaria or any other country in which Chaos, its subsidiaries,
                affiliates, or authorized resellers maintain facilities. By obtaining Digital Representations, Client
                consents to the transfer of such information outside of Client's country. In any case such personal
                information and data will be processed only for the purposes of this Agreement.
              </p>
              <p>
                9.3. Personal information and data may be processed only for the performance of this Agreement, for
                administration and authentication purposes necessary for the execution of the Agreement, compliance with
                a legal obligation or to respond to support inquiries.
              </p>
              <p>
                9.4. Chaos may provide personal information and data to its subsidiaries, affiliates or authorized
                resellers in connection with the provision, maintenance, administration or usage of Digital
                Representations. Personally identifiable information and data will not be disclosed to external third
                parties, not described herein, without Client's consent. If Chaos is required, by law or otherwise, to
                provide personal information and data to an authorized organization, Client would be informed prior to
                such disclosure.
              </p>
              <p>
                9.5. Chaos will keep Client's personal information and data for as long as necessary to fulfil the above
                purposes or as required by law.
              </p>
            </li>

            <li>
              <h3>10. OWNERSHIP</h3>
              <p>
                10.1. Client acknowledges and agrees that the Digital Representations are licensed, not sold. Client is
                granted only such rights as expressly described in this Agreement, and Client has no other rights,
                implied or otherwise.
              </p>
              <p>
                10.2. Client hereby acknowledges and agrees that all proprietary rights over the Digital
                Representations, Collateral Products, including, without limitation, any related IP Rights, belong
                solely to Chaos and its licensors. Client acknowledges and agrees that possession or use of the Digital
                Representations does not transfer to Client any proprietary rights, including without limitations any IP
                Rights.
              </p>
            </li>

            <li>
              <h3>11. SUPPORT</h3>
              <p>
                Nothing in this Agreement shall obligate Chaos to provide any support for the Digital Representations.
                Chaos may, but shall be under no obligation to, provide support with regards to the Digital
                Representations and/or the Collateral Products, if applicable.
              </p>
            </li>

            <li>
              <h3>12. REPRESENTATIONS AND WARRANTIES</h3>
              <p>
                12.1. Each Party represents and warrants that it has the authority to enter into this Agreement, that it
                is not bound by any agreement, obligation or restriction which interferes, and will not enter into any
                agreement or assume any obligation or restriction which would interfere, with any of its obligations
                hereunder.
              </p>
              <p>
                12.2. Client represents and warrants that by using the Digital Representations, as permitted herein,
                Client does not and is not going to infringe any third party’s IP Rights.
              </p>
            </li>

            <li>
              <h3>13. DISCLAIMER OF WARRANTIES</h3>
              <p className="uppercase">
                TO THE MAXIMUM EXTENT PERMITED BY APPLICABLE LAW, CHAOS EXPRESSLY DISCLAIMS ANY WARRANTY FOR THE DIGITAL
                REPRESENTATIONS. THE DIGITAL REPRESENTATIONS ARE PROVIDED “AS IS,” AND CLIENT ACKNOWLEDGES THAT CLIENT
                RECEIVES NO WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SECURITY OR NONINFRINGEMENT. THE
                FOREGOING IS FURTHER NOT ENLARGED OR OTHERWISE AFFECTED BY CHAOS’S RENDERING OR ANY SUPPORT SERVICES OR
                TECHNICAL OR OTHER ADVICE OR COMMUNICATION IN CONNECTION WITH THE DIGITAL REPRESENTATIONS OR THEIR USE.
                CHAOS DOES NOT WARRANT THAT THE DIGITAL REPRESENTATIONS WILL MEET CLIENT’S EXPECTATIONS, THAT THE
                PERFORMANCE OR OUTPUT OF THE DIGITAL REPRESENTATIONS WILL BE UNINTERRUPTED, ERROR-FREE, ACCURATE,
                RELIABLE, OR COMPLETE NOR THAT DEFECTS OR FAULTS WILL BE CORRECTED. THE DIGITAL REPRESENTATIONS ARE NOT
                A SUBSTITUTE FOR PROFESSIONAL JUDGMENT OR INDEPENDENT TESTING OF PHYSICAL PROTOTYPES.
              </p>
            </li>

            <li>
              <h3>14. LIMITATIONS OF LIABILITY</h3>
              <p className="uppercase">
                TO THE EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO EVENT WILL CHAOS BE LIABLE (DIRECTLY OR
                INDIRECTLY) TO CLIENT OR ANY OTHER THIRD PARTY FOR ANY LOSS, DAMAGES, CLAIMS, OR COSTS WHATSOEVER
                INCLUDING, NOT LIMITED TO ANY INCIDENTAL, SPECIAL, INDIRECT, CONSEQUENTIAL, PUNITIVE DAMAGES OR DAMAGES
                FROM BUSINESS INTERRUPTION, LOSS OF PROFITS, REVENUE, BUSINESS OR DATA, EVEN IF CHAOS HAS BEEN ADVISED
                OF THE POSSIBILITY OF SUCH LOSS, DAMAGES, CLAIMS, OR COSTS. CLIENT ACKNOWLEDGES AND AGREES THAT IN ANY
                EVENT THE AGGREGATE LIABILITY OF CHAOS ARISING OUT OF OR IN CONNECTION TO THIS AGREEMENT WILL BE LIMITED
                TO THE AMOUNT PAID BY CLIENT, IF ANY, EVEN IF THAT AMOUNT MAY BE SUBSTANTIALLY DISPROPORTIONATE TO THE
                REMEDY CLAIMED. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
                CONSEQUENTIAL DAMAGES, SO THIS EXCLUSION AND LIMITATION MAY NOT BE APPLICABLE.
              </p>
            </li>

            <li>
              <h3>15. TERM AND TERMINATION</h3>
              <p>
                15.1. Subject to the terms and conditions hereof, this Agreement shall be effective as of the Effective
                Date and continue in full force and effect, unless earlier terminated by any Party, for 1 (one) year and
                shall be automatically deemed extended for the same period of time and under the same terms and
                conditions as this one, in the event that neither Party gives a termination notice in writing to the
                other Party until one month before the expiration date of this Agreement. This Agreement may be
                terminated (a) at any time by mutual agreement, (b) by either Party with a three months prior written
                notice to the other Party, or (c) in case of a material breach of the Agreement, with a one month prior
                written notice from the non-breaching Party to the breaching Party, if the breaching Party fails to cure
                such breach to the reasonable satisfaction of the non-breaching Party within the above mentioned one
                month written notice period. Any notice sent to Client shall be deemed delivered and acknowledged if
                addressed to Client’s e-mail address used as at the time of registration and acceptance of the
                Agreement.
              </p>
              <p>
                15.2. The termination of this Agreement does not affect any rights or obligations of either Party which
                have arisen or accrued up to and including the date of termination, including the right to payment under
                this Agreement.
              </p>
              <p>
                15.3. Upon the termination of the Agreement for whatsoever reason, Client will cease all use of the
                Digital Representations and destroy all copies, full or partial, of the Digital Representations, unless
                otherwise agreed expressly in writing by Parties.
              </p>
              <p>
                15.4. All provisions of this Agreement, which, in accordance with their terms, are intended to have
                effect after termination or expiration of this Agreement, shall survive termination or expiration of
                this Agreement and shall continue to bind Client, its successors and assigns.
              </p>
            </li>

            <li>
              <h3>16. OTHER PROVISIONS</h3>
              <p>
                16.1. This Agreement shall be governed by the laws of Republic of Bulgaria. Any dispute about the
                existence and the validity of the Agreement, or in relation with the Agreement or with a breach of it,
                including any dispute and discrepancy about the effect, interpretation, termination, performance or
                failure to execute it, will be settled by negotiations. In the event no solution is achieved, the
                dispute will be taken to the Court of Arbitration at the Bulgarian Industrial Association (BIA), Sofia,
                Bulgaria. Nothing in the foregoing will prevent Chaos from bringing an action for infringement of IP
                Rights in any country where such infringement is alleged to occur.
              </p>
              <p>
                16.2. Client may not assign this Agreement or any rights hereunder (whether by purchase of stock or
                assets, merger, change of control, operation of law, or otherwise) without Chaos’s prior written
                consent, which may be withheld at Chaos’s sole and absolute discretion, and any unauthorized purported
                assignment by Client will be void. Client acknowledges and agrees that Chaos may assign or sub-contract
                any of its rights or obligations under this Agreement.
              </p>
              <p>
                16.3. In the event any of the terms of the Agreement is declared void because it conflicts with the
                applicable law, the rest of the terms and the Agreement as a whole will remain in full effect. Such
                invalid term will be superseded by the legal provisions.
              </p>
              <p>
                16.4. Any amendment or modification of the Agreement shall only be made by an additional agreement made
                in writing between the Parties (annex) and signed by both of them.
              </p>
              <p>
                16.5. No term or provision of this Agreement will be considered waived, and no breach excused, unless
                such waiver is in writing signed on behalf of the Party against which the waiver is asserted. No waiver
                (whether express or implied) will constitute consent to, waiver of, or excuse of any other, different,
                or subsequent breach.
              </p>
              <p>
                16.6. Chaos will not be liable for any loss, damage or penalty if Digital Representations is not
                performing or if Client is not able to use any or all of the Digital Representations’ features due to a
                force majeure (including, but not limited to short-circuits, power outages, internet network
                malfunctions, administrative limitations and others such as a war, strike, riot, crime, or an event
                described by the legal term 'act of God' e.g., flooding, earthquake, etc.) or other causes beyond
                Chaos’s reasonable control.
              </p>
              <p>
                16.7. Headings used in this Agreement are provided for convenience only and shall not be used to
                construe meaning or intent.
              </p>
              <p>
                16.8. Notices in connection with this Agreement will be in writing and will be sent by postal service or
                a delivery service. Notices will be effective when delivered and received by Chaos at: Chaos Software
                Ltd., Mladost-1A, block 548 entrance B, 2nd floor, Sofia 1729, Bulgaria; and by Client at: (please fill
                in your Client name here), having its seat and registered address in (please fill in your registered
                address here).
              </p>
              <p>
                Any notice given shall be deemed to have been received on the date which it is delivered if delivered
                personally, or, if mailed, on the date when received by addressee. Either Party may change its address
                for notices by giving notice of such change as required pursuant to this Section.
              </p>
            </li>
          </ul>
        </DialogContent>

        <DialogActions className="actions">
          <Button className="cancel" onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button
            className="confirm"
            onClick={() => {
              handleConfirmTherms();
              onConfirm();
            }}
            color="primary"
            autoFocus
          >
            I Agree
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

ThermsDialog.defaultProps = {
  isOpen: true,
};

function handleConfirmTherms() {
  window.localStorage.setItem('terms_accepted', 'accepted');
}
