import { createStore, applyMiddleware, compose } from 'redux';
import { sagaMiddleware } from './sagas';
import reducers from './reducers';

const reduxToolsPresent = () => typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function';
// eslint assumes process is undefined
// eslint-disable-next-line no-undef
const isDevelopment = () => process.env.NODE_ENV === 'development';

// Enable use of Redux devtools in development
const composeEnhancer = isDevelopment() && reduxToolsPresent() ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(reducers, composeEnhancer(applyMiddleware(sagaMiddleware)));

export default store;
