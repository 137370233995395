export const kr = {
  translation: {
    'Select your product.': '당신의 제품을 선택하십시오.', // New
    'Select the version.': 'Select the version.',
    'Select the version of your host platform.': 'Select the version of your host platform.',
    Download: '다운로드',
    Downloads: '다운로드',
    'Back to all products': '전체 제품항목으로 돌아가기', // New
    'Use the filter to find your product': '제품을 필터로 검색하기', // New
    'See all products': '모든 제품 살펴보기', // New
    'Download for another OS': '다른 OS 다운로드',
    'Download for': '다운로드',
    'Show all versions': '모든 버전 보여주기', // New
    'All fields are required': '모든 항목에 기입하여 주십시오', // New
    'Platform version': '플랫폼 버전',
    'License type': '라이선스 종류',
    'Please select': '선택하여 주십시오', // New
    Search: '찾아보기', // New
    '1 item found': '1 하나 : 아이템',
    '{n} items found': '{{n}} 나머지 : 아이템',
    'Release date': '출시일자',
    'File Size': '파일 사이즈',
    'File size': '파일 사이즈',
    "What's new": '새로운 소식',
    'Requirements & changelog': '요구사항 및 변경내용',
    items: '나머지 : 아이템',
    new: 'new', // New
    'Uploaded before': '이미 업로드됨', // New
    'Material type': '매터리얼 종류',
    'Material color': '매터리얼 색상',
    'Suitable for': '적합한',
    Manufacturer: '제조',
    Manufacturers: '제조업체', // New
    Tags: '태그',
    'Clear all': '전부 지우기',
    'File name': '파일 네임',
    'Note: Due to manufacturing process and use of natural materials, final color may vary. Please reach out to the manufacturer for more details.':
      '제조 공정의 특성 및 천연 재료의 사용으로 인해 물리적 재료의 실제 색상과 패턴이 달라질 수 있습니다',
    'Search Chaos Scans': 'Chaos Scans 찾아보기',
    'show all results': '모든 결과 보기',
    'No results found.': '결과가 없습니다.',

    // Filters
    '1 Week': '1주일', // New
    '1 Month': '한 달', // New
    '6 Months': '6개월', // New

    'Car Paint': '카 페인트',
    Carbon: '탄소',
    Ceramic: '세라믹',
    Fabric: '패브릭',
    Foam: '거품',
    Foil: '포일',
    Hologram: '홀로그램',
    Leather: '가죽',
    Metal: '메탈',
    Paint: '페인트',
    Paper: '종이',
    Plastic: '플라스틱',
    Rubber: '고무',
    Wood: '나무',

    Automotive: '자동차',
    'Interior Design': '인테리어 디자인',
    'Product Design': '제품 디자인',
    Aerospace: '항공우주', // New

    Generic: '일반',
    'Townsend Leather Company, Inc.': 'Townsend Leather Company, Inc.',
    'Handy Living': 'Handy Living',
    'Kawashima Selkon Textiles Co., Ltd.': 'Kawashima Selkon Textiles Co., Ltd.',
    'Nihon Etching Co., Ltd.': 'Nihon Etching Co., Ltd.',
    'Tanazawa Hakkosha Co., Ltd': 'Tanazawa Hakkosha Co., Ltd',

    Alcantara: 'Alcantara',
    Aluminum: '알루미늄',
    Brass: '황동',
    Brushed: '브러쉬',
    Copper: '구리',
    Crocodile: '악어',
    Galvanized: '아연',
    Glossy: '광택',
    Gold: '골드',
    Matte: '무광',
    Mirror: '거울',
    Pattern: '패턴',
    Perforated: '구멍난',
    Rust: '녹슨',
    Scratched: '스크래치',
    Tin: '주석',
    Volumetric: 'Volumetric',
    'V-Ray Next Recommended': 'V-Ray Next버전이 필요합니다', // New
    Wool: 'Wool', // New
    Retroreflective: '역반사', // New
    Chameleon: '카멜레온', // New
    Grain: '곡식', // New
    Flat: '평평한', // New
    Smooth: '매끄러운', // New
    Translucent: '반투명한', // New

    // Colors
    White: '화이트',
    Yellow: '노란',
    Beige: '베이지',
    Orange: '오렌지',
    Fuchsia: '자홍색',
    Red: '레드',
    Pink: '핑크',
    Silver: '실버',
    Gray: '회색',
    Olive: '올리브',
    Purple: '보라',
    Maroon: '밤색',
    Aqua: '아쿠아',
    Lime: '라임',
    Teal: '청록색',
    Green: '그린',
    Blue: '블루',
    Navy: '네이비',
    Brown: '브라운',
    Black: '블랙',
  },
};
