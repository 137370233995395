import { call, put, fork } from 'redux-saga/effects';
import { handleLanguage, navigateWithLanguage } from '../../sagas';

// eslint-disable-next-line complexity
export function* productCodeRedirect(payload) {
  yield fork(handleLanguage);

  const parsed = yield call(parse, payload.code);
  const query = {};

  // V-Ray Render node
  if (parsed.product && parsed.product.code === 'RN') {
    parsed.platform = { id: window.platforms.Standalone.id };
  }

  if (parsed.product && parsed.platform) {
    query.product = parsed.product.id;
    query.platform = parsed.platform.id;
    query.platform_version = -1;

    if (parsed.licenseType) {
      query.license_type = parsed.licenseType;
    }
  }

  // Vrscans
  if (parsed.product && parsed.product.code === 'VS') {
    yield put(navigateWithLanguage('VRSCANS'));
  }

  yield put(navigateWithLanguage('BUILDS', {}, { query }));
}

export function hideProduct(id) {
  const allowedProductIds = Object.values(window.productFamilies).map((product) => product.id.toString());

  return !allowedProductIds.includes(id.toString());
}

function parse(code) {
  const codes = {
    product: code.substring(0, 2),
    // Product Family
    // VR - V-Ray
    // PH - Phoenix
    // VS - VRscans - renamed to Chaos Scans
    // PD - Pdplayer
    // CR - Corona
    // CL - Chaos Cloud
    // RN - V-Ray Render Node
    // BE - Beekeeper
    // QN - Queen Render Node
    // FE - Fees - used for EDU exams fees, Services product, Renderfarm products, Some Integrations products, Royalties
    // RN - V-Ray Render Node products have to redirect to V-Ray Standalone
    // EN - Enscape
    // AX - Anima All

    version: code.substring(2, 4),
    // Product Version
    // 02 - 2
    // 03 - 3
    // 04 - Next
    // 00 - only version / evergreen license
    // 99 - legacy products

    platform: code.substring(4, 6),
    // Platform Name / Product Type
    // MX - 3ds Max
    // MA - Maya
    // CI - Cinema 4D
    // NU - Nuke
    // FZ - FormZ
    // SK - SketchUp
    // RH - Rhino
    // RE - Revit
    // UN - Unreal
    // BL - Blender
    // HO - Houdini
    // SI - Softimage
    // AP - App SDK
    // SL - Simulation License for Phoenix FD
    // RN - Render Node License for VRScans / V-Ray Standalone
    // NO - no platform / universal for all platforms

    type: code.substring(8, 9),
    // Product Type:
    // S - Standard
    // E - Evaluation
    // T - Trial
    // B - Beta (private)
    // D - Dev (for Beekeeper and Queen Render Node)
    // I - Internal Use (used for Cloud Credits)
    // N - No license provided with product
    // C - Complementary (for Cloud products)
    // P - Personal Learning Edition (PLE)
    // L - Beta (public)

    subType: code.substring(9, 10),
    // Product sub-type
    // C - commercial
    // S - student / educator
    // U - university
    // E - general academic or EDU product
    // T - training center / exams / V-Ray Academy fee
    // A - additional university license
  };

  const parsed = {
    product:
      codes.product === window.productFamilies.Enscape.code && codes.type === 'L'
        ? window.productFamilies.EnscapePreview
        : Object.values(window.productFamilies).find((p) => p.code === codes.product),
    platform: Object.values(window.platforms).find((p) => p.codes.includes(codes.platform)),
    licenseType: parseLicenseType(codes),
  };

  return parsed;
}

function parseLicenseType(codes) {
  switch (codes.type) {
    case 'S':
    case 'E':
      return 'adv';
    case 'T':
      return 'trial';
    case 'B':
      return 'license_type_beta';
    case 'P':
      return 'ple';
    case 'J':
    case 'V':
      return 'integrators';
    default:
      return 'na';
  }
}
